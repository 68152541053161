export const BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL ||"https://nitjsr.ac.in/backend" ||
  "http://localhost:3030";

export const BRANCH = {
  CH: "Department of Chemistry",
  MA: " Department Of Mathematics",
  PH: "Department of Physics",
  HU: "Department of Humanities, Social Sciences and Management",
  CA: "Computer Applications",
  ME: "Mechanical Engineering",
  CE: "Civil Engineering	",
  MM: "Metallurgical and Materials Engineering",
  PI: "Production and Industrial Engineering",
  CS: "Computer Science and Engineering",
  EE: "Electrical Engineering",
  EC: "Electronics and Communication Engineering",
};
