//Department data
//import img1 from "../images/Faculty/Sanjay.jpg";
import img1 from "../images/Faculty/ccfaculty.jpg";
import img2 from "../images/Faculty/hod_civil.jpg";
import img3 from "../images/Faculty/hod_ece.jpg";
import img5 from "../images/Faculty/hod_mec.jpg";
import img6 from "../images/Faculty/hod_met.jpg";
import img7 from "../images/Faculty/hod_prod.jpg";
import img8 from "../images/Faculty/hod_phys.jpg";
import img10 from "../images/Faculty/hod_math.png";
import img12 from "../images/Faculty/hod_hum.jpg";
import img13 from "../images/Faculty/hod_chem.jpg";

import image1 from "../images/Faculty/default.jpg";
import image2 from "../images/Faculty/CS15.jpg";
import image3 from "../images/Faculty/CS24.jpg";
import image4 from "../images/Faculty/rrsuman_cse.jpg";
import image5 from "../images/Faculty/CS5001.jpg";
import image6 from "../images/Faculty/Sanjay.jpg";
import image7 from "../images/Faculty/CS34.jpg";
import image8 from "../images/Faculty/CS33.png";

export const DeptData = [
  {
    id: 1,
    dept: `cs`,
    title: "Computer Science and Engineering",

    desc: "The Department of Computer Science and Engineering at the National Institute of Technology Jamshedpur, was formed in 1992. Since its inception, the department has always been recognized all over the country for its excellence. The Department has consistently produced quality professionals in the field of Computer Science & Engineering and strived for excellence in research and development.",

    vision:
      "Producing high quality industry ready Computer engineers to cater to the needs of the society.",

    mission: `
        To train young minds and to equip them with the best possible technical knowledge to meet the current and future demands of the industry, academia & research. 
        To create infrastructure, motivation and culture for the state of the art research work in the area of Computer Science & Information Technology.
        `,

    hod: {
      name: "Prof. DA Khan",
      designation: "Professor",
      image: img1,
    },
    contact: {
      PhoneHodOffice: "",
      PhoneHod: "",
      email: " hod.cse@nitjsr.ac.in",
      fax: "",
      address: "P.O.RIT,Jamshedpur, Jharkhand,India- 831014",
    },
  },

  {
    id: 2,
    dept: "civil",
    title: "Civil Engineering",
    desc: "The Department of Civil Engineering of National Institute of Technology (formerly Regional Institute of Technology) Jamshedpur was started since the inception of the institute itself in 1960. This core department has sanctioned strength of 33 (thirty-three) faculty members with flexible faculty structure. The department is running UG course for B. Tech. (Hons) degree in Civil Engineering and PG courses as M. Tech. degree in the streams of Structural Engineering, Geotechnical Engineering and Water Resource Engineering as well as Ph. D. program in various streams of Civil Engineering. The department has well qualified and experienced faculty members. Apart from teaching and research, all faculty members are actively involved in administrative woks and other extracurricular activities for all round development of the Institute. Faculty members of the department are also undertaking design, testing & consultancy works for various organizations leading to resource generation for the Institute.",

    vision:
      "To become the best department for the research, academic and consultancy with the vigorous participation of faculty, research scholars and students and produce smart solutions with ethics and morals in the field of civil engineering for the benefit of society and industry.",
    mission: `
        To develop modern laboratories, centre of excellence and advanced computational facilities.
        To conduct research in all fields of civil engineering and interdisciplinary areas.
        To motivate research scholars and students towards best professional practices.
        To make graduating students exposed to industry and R & D organizations to get better opportunities.
        To offer educational programmes that imparts inventive knowledge with high levels of ethical and human values.
        `,
    hod: { name: " Dr. Akhileshwar Kumar Singh", designation: "", image: img2 },
    contact: {
      PhoneHodOffice: "+91-657-237-4006",
      PhoneHod: "+91-9430746532",
      email: "hod.civil@nitjsr.ac.in",
      fax: "",
      address: " P.O.RIT,Jamshedpur, Jharkhand,India- 831014",
    },
  },
  {
    id: 3,
    dept: "ece",
    title: "Electronics and Communication Engineering",
    desc: "Department of Electronics and Communication Engineering was started in 1989. The department covers a host of subjects inclusive of Electronic Circuits, Microprocessor, Digital signal processing, Analog communication, Digital communication, Mobile communication, VLSI, Embedded systems, Instrumentation etc. The department has laboratories catering to all the subjects of studies. There are five research scholars working in different specializations under the groups of Communication Engineering, VLSI and Embedded systems, Signal Processing, Instrumentation and Soft computing. The Department has highly motivated faculty pool to cater our needs.",
    vision: `
        Global Knowledge Hub
        Excellence in technical and interdisciplinary domain
        Amalgam of research and entrepreneurship in pursuit of Make in India drive
        Beckoning pillar for digital India
        `,
    mission: `
        Imparting total quality education to develop next generation professional scholar, fit for globally competitive environment.
        Value creation through economic, social, and environmental development using original ideas through discovery, inquiry, innovation, research, transformational scholarship, and creative activities.
        Dramatic increase in product oriented research for establishing a self-sustaining and wealth creating centre by involving the interaction between committed, diverse faculty, staff, students and community, to serve the societal needs of the State of Jharkhand, India and ultimately, the world.
        `,
    hod: { name: "Dr. Dilip Kumar", designation: "", image: img3 },
    contact: {
      PhoneHodOffice: "",
      PhoneHod: "+91-9431905737",
      email: "hod.ece@nitjsr.ac.in",
      fax: "",
      address: " P.O.RIT,Jamshedpur, Jharkhand,India- 831014",
    },
  },

  {
    id: 4,
    dept: "eee",
    title: "Electrical Engineering",
    desc: "The Department of Electrical Engineering was started in 1960. The Department has been consistently producing quality Electrical Engineers since its inception and is also involved in research and development activities. The alumni of the department are well placed in both public and private sectors. In addition to the UG program the department runs PG program in Power Systems and Ph.D. program in different areas of specialization.",
    vision: `To be a centre for imparting world class education in the field of electrical engineering and conduct extensive and quality research and to propel innovations for the betterment of society.`,
    mission: `To train young minds and to equip them with the best possible technical knowledge to meet the current and future demands of the industry, to recognize education and research in close interaction with the industry and installing leadership qualities in young men and women who enter the portals of the institute with sensitivity for social development and with an eye for growth in international perspective.`,
    hod: { name: "Dr. Ashok Kumar Akella", designation: "", image: "" },
    contact: {
      PhoneHodOffice: "+91-657-237-4069",
      PhoneHod: "+91-9431952816",
      email: "hod.eee@nitjsr.ac.in",
      fax: "",
      address: " P.O.RIT,Jamshedpur, Jharkhand,India- 831014",
    },
  },
  {
    id: 5,
    dept: "mech",
    title: "Mechanical Engineering",
    desc: "The Department of Mechanical Engineering is one of the oldest (started in 1960) and the largest department in terms of faculty, students and activities. The Department offers B.Tech (Hons.) in Mechanical Engineering, M.Tech in Computer Integrated Design & Manufacturing (CIDM), M.Tech in Thermal Engineering (TE) and M.Tech in Energy System (ES). Department is also running PhD Program in various research areas in the Mechanical Engineering. It also offers part time PhD program, mainly meant for those employed in industries and academic institutions. The department is having highly qualified and experienced faculty in all streams of Mechanical Engineering. An interactive relationship is maintained between the students and staff which enables the students to develop a sound foundation in the stream in a conduciven environment.",
    vision: `To be a centre for imparting world class education in the field of Mechanical Engineering and conduct extensive and quality research and to propel innovations for the betterment of society.`,
    mission: `
        
        To train young minds and to equip them with the best possible technical knowledge to meet the current and future demands of the industry, academic & research.
        To create infrastructure, motivation and culture for the state of the art research work in the area of Mechanical Engineering.
        To enhance the research and teaching by interaction and development of relation with industry, R&D organization and academic institutions.
        To develop an energetic environment for excellence, creativity and perfection.
        `,
    hod: { name: "Prof. Shalendra Kumar", designation: "", image: img5 },
    contact: {
      PhoneHodOffice: "+91-657-237-4012",
      PhoneHod: "+91-657-2374020",
      email: "hod.me@nitjsr.ac.in",
      fax: "",
      address: " P.O.RIT,Jamshedpur, Jharkhand,India- 831014",
    },
  },

  {
    id: 6,
    dept: "meta",
    title: "Metallurgical and Materials Engineering",
    desc: `
        The Department of Metallurgical and Materials Engineering was amongst the four branches with which the institute was initially launched in 1960, with increasing focus on :<br/>
        Materials Technology
        Foundry Technology
        Iron & Steel
        Nanotechnology
        Mineral Benefaction<br/>
        and the R&D work being carried out in these fields in the metallurgical sector, Materials Engineering was included in the curriculum of the department in 1995.There are M.Tech course on Materials Technology & Foundry Technology.
        <br/>
        <p>Current Research is going on: </p>
        Physical Metallurgy
        Hydro Metallurgy
        Process Metallurgy
        Mineral Characterisation & Beneficiation
        `,
    vision: ``,
    mission: ``,
    hod: { name: " Dr. Ashok Kumar", designation: "", image: img6 },
    contact: {
      PhoneHodOffice: "0657-237-4026",
      PhoneHod: "+91-9470525571",
      email: "hod.met@nitjsr.ac.in",
      fax: "",
      address: " P.O.RIT,Jamshedpur, Jharkhand,India- 831014",
    },
  },

  {
    id: 7,
    dept: "prod",
    title: "Production and Industrial Engineering",
    desc: "The Department of Production & Industrial Engineering was started in year 1988 with the name of “Production Engineering & Management”. The name of this department was changed to Production & Industrial Engineering in the year 2007. The department was accredited by NBA in 2009. This core department has a sanctioned strength of 16 (Sixteen) faculty members with flexible faculty structure. Presently, there are 02 Professor, 03 Associate professors and 08 Assistant Professors. Every faculty member has received doctorate degree in different research areas. The department is running a UG course for B. Tech. (Hons.) degree in Production and industrial Engineering and a PG course in Manufacturing Systems Engineering as well as Ph. D. program in various streams of Production and Industrial Engineering. The Department is currently developing different engineering laboratories like CAD/CAM Lab., Advanced Manufacturing and Flexible manufacturing systems Lab., Non- Traditional Manufacturing lab, and Industrial Engineering & Ergonomics lab and Central workshop. Time to time curricula/syllabus is upgraded as per the market and technology requirement. The department has also proposed to start a new PG course in Production Management which will cater to the current industrial need.",
    vision: `To produce engineers and researchers with excellent technical knowledge, research acumen, leadership skills and ethical responsibility.`,
    mission: `
        To educate B. Tech., M. Tech and Ph. D students with fundamental knowledge in concerned subjects, and to update them with the state-of-the-art in the field.
        To develop and maintain laboratories which cater to the needs of the students
        Quest for inter-disciplinary research.
        To promote academia-industry collaboration
        `,
    hod: { name: "Dr. Ashok Kumar Jha", designation: "", image: img7 },
    contact: {
      PhoneHodOffice: "",
      PhoneHod: "+91-8987571650",
      email: "ashokjha.prod@nitjsr.ac.in",
      fax: "",
      address: " P.O.RIT,Jamshedpur, Jharkhand,India- 831014",
    },
  },

  {
    id: 8,
    dept: "chem",
    title: "Chemistry",
    desc: "Department of Chemistry, National Institute of Technology, Jamshedpur was originally founded as Regional Institute of Technology in 11260 with the aim of nurturing talent and setting high standards of education and excellence. The Department offers instruction in General Chemistry and Engineering Chemistry to the students in different branches of Engineering students at B.Tech level. The Department started Post Graduate Programme (M.Tech) in Surface Science and Engineering since 1982.The department is equipped with various instruments. Besides teaching, the highly qualified and energetic faculty members of the Department have contributed a lot to elevate the prestige of the institute through their research activities of both basic and applied areas of chemistry.",

    vision: `
      To create the Department of Chemistry as a centre of excellence in science and
      technology through enhanced collaboration between Industries and academia and
      serving the country.
      Building world class research facility to train young minds through extensive research
      curriculum.
      To provide a pool of bright scientists for advanced scientific endeavour of our
      country.
    `,

    mission: `
        To provide good fundamental concept of Chemistry applied to the Engineering field.
        To introduce the importance of Chemistry in industry as well as in research field.
        To study the importance of chemicals, chemical reactions and physical phenomena, synthesis in higher study of Engineering.
        `,
    hod: { name: "Dr. Ananta Kumar Atta", designation: "", image: img13 },
    contact: {
      PhoneHodOffice: "0657 2374044",
      PhoneHod: "NA",
      email: "hod.chem@nitjsr.ac.in",
      fax: "",
      address: " P.O.RIT,Jamshedpur,Jharkhand,India- 831014",
    },
  },

  {
    id: 9,
    dept: "mca",
    title: "Computer Application",
    desc: "The Department of Computer Applications at the National Institute of Technology Jamshedpur, was formed in 1987. Since its inception, the department has always been recognized all over the country for its excellence. The Department has consistently produced quality professionals in the field of Computer & Network and strived for excellence in research and development. MIS of NIT Jamshedpur is managed by Department of Computer Applications.",

    vision: ``,

    mission: ``,
    hod: { name: "Prof. Dilip Kumar Yadav", designation: "", image: "" },
    contact: {
      PhoneHodOffice: "+91-657-237-4119",
      PhoneHod: "+91-9931897599",
      email: " hod.ca@nitjsr.ac.in",
      fax: "",
      address: " P.O.RIT,Jamshedpur,Jharkhand,India- 831014",
    },
  },

  {
    id: 10,
    dept: "maths",
    title: "Maths",
    desc: "Department of Mathematics was established since the inception of National Institute of Technology as Regional Institute of Technology, Jamshedpur in 1960. The Department provides an outstanding research environment complemented by excellent teaching for its students to flourish in different areas of academics and industry as well. The department has produced a good number of Ph.D students. The students of the department are well placed in leading Academic Institution, Research and Development organizations and Software industries like IIT’s, ONGC, IBM, WIPRO etc. Currently, Department has initiated M.Sc. Mathematics programme. Also, Department is planning to start new progammes like, 2-year M.Sc. in Applied Mathematics and 5-year Integrated M.Sc. programmes.",

    vision: ``,

    mission: `
    To provide sound fundamental of the subject.
    To focus on emerging area of Research fields.
    To link students with industries and Labs so that they visualize the real power of subject.
    `,
    hod: { name: "Dr. Raj Nandkeolyar", designation: "", image: img10 },
    contact: {
      PhoneHodOffice: "",
      PhoneHod: "+91-7837181319",
      email: "hod.math@nitjsr.ac.in",
      fax: "",
      address: " P.O.RIT,Jamshedpur,Jharkhand,India- 831014",
    },
  },

  {
    id: 11,
    dept: "phys",
    title: "Physics",
    desc: "The Department of Physics at the National Institute of Technology, Jamshedpur, was formed in 1960. Since its inception, the department has been recognized all over the country for its excellence. The department has consistently produced quality professionals in the field of physics and strived for excellence in research and development. The Department has at present 9 faculty members including DST-Inspire Faculty and 11 research scholars engaged in doctoral research.The Department actively participates in front-line research in several major areas of Physics including Solar Energy, Nuclear scattering and Supersymmetry, Mathematical Physics, Holography and holographic optical element, Speckle Meterology, Condensed Matter Physics, Plasma Physics, Computational Physics and Spectroscopy.At present department offer 2 years Master of Science (Physics) Programme as well as a Ph.D. Programme with specialization in various major areas of Physics. The Department also participates in teaching Engineering Physics to B.Tech. (Hons.) students of all the disciplines namely- Civil Engineering, Computer Science and Engineering, Electrical and Electronics Engineering, Electronics and Communication Engineering, Production and Industrial Engineering, Mechanical Engineering and Metallurgical & Materials Engineering of the Institute. Our M.Sc. students are ranking good in national level competitive exams like CSIR-NET, GATE, JEST as well as in the INSPIRE Fellow scheme offered by the Department of Science and Technology (DST), India.",

    vision: `
    Quality education in physics and produce highly technically trained human resources of world class.
    Promote research and development in applied physics in the emerging and thrust areas and attainment of academic excellence.
    Provide greater understanding of the nature of physical science, an appreciation of physics in everyday life, and technical expertise.
    Excellence in technical and multidisciplinary domain.
    
    `,

    mission: `
    To provide quality education, understanding in physics and development of experimental skills to produce well prepared graduates.
    To provide dynamic, forward looking and responsive to global technological development  by imparting excellent technical education.
    `,
    hod: { name: "Prof. Ujjwal Laha", designation: "", image: img8 },
    contact: {
      PhoneHodOffice: "0657-237-4039",
      PhoneHod: "+91-9430308658",
      email: "ulaha.phy@nitjsr.ac.in",
      fax: "",
      address: " P.O.RIT,Jamshedpur,Jharkhand,India- 831014",
    },
  },

  {
    id: 12,
    dept: "humanities",
    title: "Humanities, Social Sciences and Management",
    desc: `When the institute was established in 1960 as Regional Institute of Technology, the Department came into being as Humanities Department and was fashioned on the lines of the Department of Humanities and Social Sciences at IITs. The Department was engaged in the teaching of English, Philosophy, Psychology, Political Science and Economics to B.Sc. (Engg.) students. In 2014, the Department was upgraded to the Department of Humanities, Social Sciences and Management. The department is currently engaged in teaching English for Communication, Introduction to Soft Skills, Organizational Behaviour and Industrial Psychology, Industrial Economics and Accountancy, Management Information System courses to B Tech (Hons.) students and Technical Communication to M.Tech,Communication kills to M.Sc,Financial Management ,Drag Behaviour and Management to MCA and Electives(principles of eco entrepreneurship Development). The faculty members of the department are also training students in the area of oral and written communication skills for placement purposes.
      The department plans to start a two year full time, post graduate MBA programme in near future.
      `,

    vision: `
    To contribute to the emergence of NIT Jamshedpur as a world class institution, producing highly skilled engineers who are finest specimens of technology at its best merging aesthetics with human values and social responsibility to make this world a better place.
    
    `,

    mission: `
    To contribute to the holistic development of the personality of the students so as to enable them to successfully meet their professional as well as societal challenges and to inculcate essential human values in them and also to make them sensitive to the concerns of environment.
    `,
    hod: { name: "Dr. Manish Kumar Jha", designation: "Associate Professor", image: img12 },
    contact: {
      PhoneHodOffice: "+91-657-2320449",
      PhoneHod: "+91-7209805149",
      email: "hod.hum@nitjsr.ac.in",
      fax: "",
      address: " P.O.RIT,Jamshedpur,Jharkhand,India- 831014",
    },
  },
];

export const FacultyStaticData = [
  {
    id: "CS001",
    image: image1,
    branchId: "CSE",
    name: "Dr. Binod Kumar Singh",
    designation: "Associate Professor",
    Department: "Computer Science and Engineering",
    link: "/Departments/Profile",
    contact: {
      address: "Dept. of CSE, NIT Jamshedpur",
      phone: "",
      mobile: "",
      email: "bksingh.cse@nitjsr.ac.in",
    },
    education:
      "B.E. (CSE), BIT SINDRI,\r\nM.Tech. (CSE), IIT Roorkee,\r\nPh.D. - IIT Roorkee.",
  },
  {
    id: "CS002",
    image: image2,
    branchId: "CSE",
    name: "Dr. Dilip Kumar",
    designation: "Assistant Professor",
    Department: "Computer Science and Engineering",
    link: "/Departments/Profile",
    contact: {
      address: "",
      phone: "0657 2374103",
      mobile: "9431184850",
      email: "dilip.cse@nitjsr.ac.in",
    },
    education: "B.E.(CSE), M.Tech(CSE), ",
  },
  {
    id: "CS003",
    image: image3,
    branchId: "CSE",
    name: "Dr. Koushlendra Kumar Singh",
    designation: "Assistant Professor",
    Department: "Computer Science and Engineering",
    link: "/Departments/Profile",
    contact: {
      address:
        " Q.N.- 57,NIT Campus, Adityapur-2, Jamshedpur, Jharkhand, PIN: 831014",
      phone: "",
      mobile: "9479845831",
      email: "koushlendra.cse@nitjsr.ac.in",
    },
    education: `
        B.Tech, BCE BHAGALPUR
        M.Tech, IIITDM JABALPUR
        PhD, IIITDM JABALPUR`,
  },
  {
    id: "CS004",
    image: image4,
    branchId: "CSE",
    name: "Mr. Rajiv Ranjan Suman",
    designation: "Associate Professor",
    Department: "Computer Science and Engineering",
    link: "/Departments/Profile",
    contact: {
      address: "D-24, NIT Jamshedpur Campus - 831014",
      phone: "",
      mobile: "8434765977",
      email: "rrsuman.cse@nitjsr.ac.in",
    },
    education: "Ph.D. (Pursuing), M.Tech, B.Tech",
  },
  {
    id: "CS005",
    image: image5,
    branchId: "CSE",
    name: "Dr. Ritesh Kumar",
    designation: "Temporary Faculty",
    Department: "Computer Science and Engineering",
    link: "/Departments/Profile",
    contact: {
      address: "DEPARTMENT OF COMPUTER SCIENCE AND ENGINEERING",
      phone: "",
      mobile: "",
      email: "ritesh.cse@nitjsr.ac.in",
    },
    education: "Ph.D. IIT (ISM) Dhanbad",
  },
  {
    id: "CS006",
    image: image6,
    branchId: "CSE",
    name: "Dr. Sanjay Kumar",
    designation: "Associate Professor",
    Department: "Computer Science and Engineering",
    link: "/Departments/Profile",
    contact: {
      address:
        "QR. No. C-5, NIT Campus,Adityapur, P.O./ P.S. - RIT, District - Saraikella Kharsawn, Jharkhand - 831014",
      phone: "0657-237-4102",
      mobile: "09431348965, 6201478517",
      email: "sanjay.cse@nitjsr.ac.in",
    },
    education: "PhD, NIT Jamshedpur",
  },
  {
    id: "CS007",
    image: image7,
    branchId: "CSE",
    name: "Dr. Subrata Dutta",
    designation: "Assistant Professor",
    Department: "Computer Science and Engineering",
    link: "/Departments/Profile",
    contact: {
      address: "Department of CSE",
      phone: "",
      mobile: "9477501086",
      email: "sdutta.cse@nitjsr.ac.in",
    },
    education:
      "M. Tech(Jadavpur University), PhD(Jadavpur University), rnPost Doc (United Kingdom)",
  },
  {
    id: "CS008",
    image: image8,
    branchId: "CSE",
    name: "Dr. Vinay Kumar",
    designation: "Assistant Professor",
    Department: "Computer Science and Engineering",
    link: "/Departments/Profile",
    contact: {
      address: "CSE Dept.",
      phone: "",
      mobile: "9454690527",
      email: "vkumar.cse@nitjsr.ac.in",
    },
    education: `Ph.D. (IIT BHU),
        M.Tech. (NIT Durgapur)`,
  },
];
