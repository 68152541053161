import React from "react";
import Container from "../../../Components/UI/Container/index";
import { Table } from "react-bootstrap";

const OtherAdministration = () => {
  const response = [
    {
      name: "Dr. Rakesh Pratap Singh",
      designation: "Chairman, Medical Services",
      email: "chairman.medical#",
    },
    {
      name: "Dr. Rajiv Bhushan",
      designation: "Prof. In-Charge, Alumni Relations",
      email: "pic.ar@nitjsr.ac.in",
    },
    {
      name: "Dr. Amit Prakash ",
      designation: "Chairman, HKGC ",
      email: "chairman.hkgc#",
    },
    {
      name: "Dr. Anil Kumar Prasad",
      designation: "Chairman, Security Services",
      email: "chairman.security#",
    },
    {
      name: " Dr. Satish Kumar",
      designation: "Faculty In-Charge, Examination",
      email: "examonline#",
    },
    {
      name: " Dr. Ashok Kumar",
      designation: "Chairman, Publication Cell",
      email: "publicationcell#",
    },
    {
      name: " Dr. Raj Ballav",
      designation: "Prof. In-Charge, Vechile",
      email: "fic.vehicle#",
    },
    {
      name: "Dr. Sanjay Kumar",
      designation: "Chairman, Hindi Cell",
      email: "----",
    },
    {
      name: "Dr. Snehasis Kundu",
      designation: "Prof. In Charge, Time Table",
      email: "----",
    },
    {
      name: " Dr. Koushlendra Kumar Singh",
      designation: "Prof. In Charge, Website",
      email: "webmaster#",
    },
    {
      name: "Mr. Sunil Kumar Bhagat",
      designation: "Public Relation Officer",
      email: "ar.pro#",
    },
    {
      name: " Dr. Subrata Dutta",
      designation: "Coordinator, C.C.",
      email: "coordinator.cc#",
    },
    {
      name: " Dr. Subrata Dutta",
      designation: "Faculty In-Charge, Network",
      email: "pi.network#",
    },
    {
      name: " Dr. Dinesh Kumar",
      designation: "Faculty In-Charge, Samarth/MIS",
      email: "samartherp#",
    },
    {
      name: " Dr. Jitesh Pradhan",
      designation: "Co-ordinator, Samarth/MIS",
      email: "mis#",
    },
    {
      name: "Dr. Jayendra Kumar",
      designation: "Prof. In Charge, NSS",
      email: "nsscoordinator#",
    },
    // {
    //   name: "Dr. S. Biswas",
    //   designation: "Faculty In Charge, UBA",
    // },
    {
      name: "Dr. Sanjay Vajpai",
      name2: "Dr. Ashok Kumar Mandal",
      designation: "Prof. In-charge, Cultural Activities",
      email: "picultural#",
    },
    {
      name: "Dr. Kunal Singh",
      name2: "Dr. Arjun Kumar",
      designation: "Prof. In-charge, Technical Activities",
      email: "pitechnical#",
    },
    {
      name: "Prof. Arvind Kumar Lal Srivastava",
     // name2: "Dr. Somenath Mondal",
      designation: "President SAC",
      email: "pisports#",
    },
    {
      name: "Prof. Anil Kumar Choudhary",
      designation: "Prof. In-charge, T&P",
      email: "pi.tap#",
    },
    {
      name: "Dr. Satish Kumar",
      designation: "Faculty In-charge - Admission",
      email: "fic.admission#",
    },
    {
      name: "Dr. Kanika Prasad",
      designation: "Prof. In-charge, UBA",
      email: "uba#",
    },
    {
      name: "Dr. Vineet Sahoo",
      designation: "Faculty In-Charge, Guest House & Badminton",
      email: "fic.guesthouse#",
    },
    {
      name: "Dr. Somenath Mondal",
      designation: "Faculty In-Charge, Football",
      email: "----",
    },
    {
      name: "Dr. Sriram Karthick Raja P",
      designation: "Faculty In-Charge, Basketball",
      email: "----",
    },
    {
      name: "Dr. Tapas Das",
      designation: "Faculty In-Charge, Kho-Kho",
      email: "----",
    },
    {
      name: "Dr. Chandrashekhar Azad",
      designation: "Faculty In-Charge, Hockey",
      email: "----",
    },
    {
      name: "Dr. Ajay Kumar",
      designation: "Faculty In-Charge, Gymnasium",
      email: "----",
    },
    {
      name: "Dr. Amit Kumar",
      designation: "Faculty In-Charge, Kabaddi & Taekwondo",
      email: "----",
    },
    {
      name: "Dr. Swagatadeb Sahoo",
      designation: "Faculty In-Charge, Body Building",
      email: "----",
    },
    {
      name: "Dr. Basudeba Behera",
      designation: "Faculty In-Charge, Chess",
      email: "----",
    },
    {
      name: "Dr. Jitendra Kumar",
      designation: "Faculty In-Charge, Yoga",
      email: "----",
    },
    {
      name: "Dr. Doreswamy",
      designation: "Faculty In-Charge, Athletics",
      email: "----",
    },
    {
      name: "Dr. Ratnesh Kumar Mishra",
      designation: "Faculty In-Charge, Table Tennis",
      email: "----",
    },
    {
      name: "Dr. Vijay Kumar Dalla",
      name2: "Dr. Vishesh Ranjan Kar",
      designation: "Faculty In-Charge, Athletics",
      email: "----",
    },
    {
      name: "Dr. Bipin Kumar",
      designation: "Faculty In-Charge, Volleyball",
      email: "----",
    },
    {
      name: "Dr. Ashish Das",
      designation: "Faculty In-Charge, Cricket",
      email: "----",
    },
  ];

  return (
    <div>
      <Container>
        <Table
          striped
          bordered
          hover
          responsive="md"
          style={{ margin: "50px auto" }}
        >
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Name</th>
              <th>Designation</th>
              <th>E-mail (replace # with @nitjsr.ac.in)</th>
            </tr>
          </thead>
          <tbody>
            {response &&
              response.map((items, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      {items.name}
                      <br></br>
                      {items.name2}
                    </td>
                    <td>{items.designation}</td>
                    <td>{items.email}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default OtherAdministration;
