import React, { Fragment } from "react";
import Box from "../../../Components/Box";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Icon } from "react-icons-kit";
import { arrowRight } from "react-icons-kit/fa/arrowRight";
import { useTranslation } from "react-i18next";

import Container from "../../../Components/UI/Container";
import Text from "../../../Components/Text";
import Heading from "../../../Components/Heading";
import {
  ParagraphWrapper,
  BoxWrapper,
} from "../../../assets/css/Custom/AcademicStyle";
import { Row, Col } from "react-bootstrap";
import { chevronCircleRight } from "react-icons-kit/fa/chevronCircleRight";
import { Helmet } from "react-helmet";
import { Table } from "react-bootstrap";
function AcademicHome({ row, col, colOne, colTwo, fontsize }) {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Helmet>
        <meta
          name="description"
          content="NIT Jamshedpur is a prestigious institute of higher learning producing meritorious students with excellent career growth and universal recognition. The students get the best of opportunities in the form of highly advanced courses and eminent faculty members.The institute is committed to academic excellence and good governance. NIT Jamshedpur was ranked overall 78th for 2015-16 among the best 100 engineering institutes in the country by the National Institutional Ranking Framework (NIRF) for higher education institutions...."
        />
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,academics,b.tech,m,tech,p.h.d.,education,engineering,opportunities,departments,studies"
        />
      </Helmet>
      <Container width="100%">
        <br />
        <Heading
          content={t("title1")}
          style={{
            fontSize: "1.5em",
            marginBottom: "15px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
        />
        <ParagraphWrapper>
          <p style={{ fontSize: fontsize }} className="paragraph">
            {t("subtitle1_para")}
          </p>
        </ParagraphWrapper>
        <Heading
          content={t("Academics_subtitle2")}
          style={{
            fontSize: "1.5em",
            marginBottom: "15px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
        />
        <Text content={t("subtitle2_para")} />
        <BoxWrapper>
          <Row>
            <Col md={4} sm={12}>
              <ul className="listClass" style={{ listStyleType: "circle" }}>
                <li>
                  <Link to="/departments/mech">
                    <p className="linkp">{t("subtitle2_table1_li1")}</p>
                  </Link>
                </li>
                <li>
                  <Link to="/departments/eee">
                    <p className="linkp">{t("subtitle2_table1_li2")}</p>
                  </Link>
                </li>
                <li>
                  <Link to="/departments/meta">
                    <p className="linkp">{t("subtitle2_table1_li3")}</p>
                  </Link>
                </li>
              </ul>
            </Col>
            <Col md={4} sm={12}>
              <ul className="listClass">
                <li>
                  <Link to="/departments/prod">
                    <p className="linkp">{t("subtitle2_table2_li1")}</p>
                  </Link>
                </li>
                <li>
                  <Link to="/departments/ece">
                    <p className="linkp">{t("subtitle2_table2_li2")}</p>
                  </Link>
                </li>
                <li>
                  <Link to="/departments/civil">
                    <p className="linkp">{t("subtitle2_table2_li3")}</p>
                  </Link>
                </li>
              </ul>
            </Col>
            <Col md={4} sm={12}>
              <ul className="listClass">
                <li>
                  <Link to="/departments/cs">
                    <p className="linkp">{t("subtitle2_table3_li1")}</p>
                  </Link>
                  <Link to="/academic/Academics">
                    <p className="linkp">{t("subtitle2_table3_li2")}</p>
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </BoxWrapper>
        <Heading
          content={t("Academics_subtitle3")}
          style={{
            fontSize: "1.5em",
            marginBottom: "15px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
        />
        <Text content={t("subtitle3_para")} />
        <BoxWrapper>
          <Row>
            <Col md={4} sm={12}>
              <ul className="listClass">
                <li>
                  <p className="linkp">{t("subtitle3_table1_li1")}</p>
                </li>
                <li>
                  <p className="linkp">{t("subtitle3_table1_li2")}</p>
                </li>
                <li>
                  <p className="linkp">{t("subtitle3_table1_li3")}</p>
                </li>
                <li>
                  <p className="linkp">{t("subtitle3_table1_li4")}</p>
                </li>
                <li>
                  <p className="linkp">{t("subtitle3_table1_li5")}</p>
                </li>
                <li>
                  <p className="linkp">{t("subtitle3_table1_li6")}</p>
                </li>
                <li>
                  <p className="linkp">{t("subtitle3_table1_li7")}</p>
                </li>
                <li>
                  <p className="linkp">{t("subtitle3_table1_li8")}</p>
                </li>
              </ul>
            </Col>
            <Col md={4} sm={12}>
              <ul className="listClass">
                <li>
                  <p className="linkp">{t("subtitle3_table2_li1")}</p>
                </li>
                <li>
                  <p className="linkp">{t("subtitle3_table2_li2")}</p>
                </li>
                <li>
                  <p className="linkp">{t("subtitle3_table2_li3")}</p>
                </li>
                <li>
                  <p className="linkp">{t("subtitle3_table2_li4")}</p>
                </li>
                <li>
                  <p className="linkp">{t("subtitle3_table2_li5")}</p>
                </li>
                {/* <li>
                  <p className="linkp">{t("subtitle3_table2_li6")}</p>
                </li>
                <li>
                  <p className="linkp">{t("subtitle3_table2_li7")}</p>
                </li> */}
                <li>
                  <p className="linkp">{t("subtitle3_table2_li8")}</p>
                </li>
              </ul>
            </Col>
            <Col md={4} sm={12}>
              <ul className="listClass">
                <li>
                  <p className="linkp">{t("subtitle3_table3_li1")}</p>
                </li>
                <li>
                  <p className="linkp">{t("subtitle3_table3_li2")}</p>
                </li>
                <li>
                  <p className="linkp">{t("subtitle3_table3_li3")}</p>
                </li>
                <li>
                  <p className="linkp">{t("subtitle3_table3_li4")}</p>
                </li>
                <li>
                  <p className="linkp">{t("subtitle3_table3_li5")}</p>
                </li>
                <li>
                  <p className="linkp">{t("subtitle3_table3_li6")}</p>
                </li>
                <li>
                  <p className="linkp">{t("subtitle3_table3_li7")}</p>
                </li>
                <li>
                  <p className="linkp">{t("subtitle3_table3_li8")}</p>
                </li>
              </ul>
            </Col>
          </Row>
        </BoxWrapper>
        <Heading
          content={t("Academics_subtitle4")}
          style={{
            fontSize: "1.5em",
            marginBottom: "15px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
        />
        <p className="linkp">
          {t("subtitle4_para")}{" "}
          <span style={{ cursor: "pointer", color: "#274187" }}>
            <Link to="/academic/Ordinance">{t("Click Here")}</Link>
          </span>
        </p>
        <Heading
          content={t("Academics_subtitle5")}
          style={{
            fontSize: "1.5em",
            marginBottom: "15px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
        />
        <BoxWrapper>
          <Box className="row" {...row}>
            <ul className="linklist">
              <li>
                <a
                  // href="http://www.nitjsr.ac.in/download/uploads/Process_and_procedure_to_collect_original_degree_from_NIT_Jamshedpur.pdf"
                  href="https://nitjsr.ac.in/backend/uploads/phd%20important%20links/One%20Time%20Relaxation%20for%20Semester%20Registration%20for%20Institute%20Faculty%20Members%20and%20Staff.pdf"
                  target="_blank"
                  without
                  rel="noreferrer"
                >
                  <Icon icon={chevronCircleRight} size={18} />
                  &nbsp;
                  {t("subtitle5_link_new_1")}
                </a>
              </li>
              <li>
                <a
                  // href="http://www.nitjsr.ac.in/download/uploads/Process_and_procedure_to_collect_Transcript_from_the_Institute.pdf"
                  href="https://nitjsr.ac.in/backend/uploads/phd%20important%20links/Leave%20Rules%20for%20PhD%20Research%20Scholars%20with%20immediate%20effect.pdf"
                  target="_blank"
                  without
                  rel="noreferrer"
                >
                  <Icon icon={chevronCircleRight} size={18} />
                  &nbsp;
                  {t("subtitle5_link_new_2")}
                </a>
              </li>
              <li>
                <a
                  // href="http://www.nitjsr.ac.in/download/uploads/Process_and_procedure_to_collect_Transcript_from_the_Institute.pdf"
                  href="https://nitjsr.ac.in/backend/uploads/phd%20important%20links/Certificates%20issued%20for%20UG%202014-18%20Batch%20for%20EEE%20and%20EE%20are%20one%20and%20the%20same.pdf"
                  target="_blank"
                  without
                  rel="noreferrer"
                >
                  <Icon icon={chevronCircleRight} size={18} />
                  &nbsp;
                  {t("subtitle5_link_new_3")}
                </a>
              </li>
              <li>
                <a
                  href="https://nitjsr.ac.in/backend/uploads/phd%20important%20links/Recovery%20of%20Stipend%20from%20Students%20of%20M.Tech%20and%20PhD%20Students.pdf"
                  target="_blank"
                  without
                  rel="noreferrer"
                >
                  <Icon icon={chevronCircleRight} size={18} />
                  &nbsp;
                  {t("subtitle5_link_new_4")}
                </a>
              </li>
              <li>
                <a
                  href="https://nitjsr.ac.in/backend/uploads/phd%20important%20links/Process%20and%20procedure%20to%20collect%20the%20original%20degree.pdf"
                  target="_blank"
                  without
                  rel="noreferrer"
                >
                  <Icon icon={chevronCircleRight} size={18} />
                  &nbsp;{t("subtitle5_link2")}
                </a>
              </li>
              <li>
                <a
                  href="https://nitjsr.ac.in/backend/uploads/phd%20important%20links/Process%20and%20procedure%20for%20Document%20Verification_Educational%20Qualification.pdf"
                  target="_blank"
                  without
                  rel="noreferrer"
                >
                  <Icon icon={chevronCircleRight} size={18} />
                  &nbsp;{t("subtitle5_link1")}
                </a>
              </li>
              <li>
                <a
                  href="https://nitjsr.ac.in/backend/uploads/phd%20important%20links/Process%20and%20procedure%20for%20collecting%20Transcript.pdf"
                  target="_blank"
                  without
                  rel="noreferrer"
                >
                  <Icon icon={chevronCircleRight} size={18} />
                  &nbsp;{t("subtitle5_link3")}
                </a>
              </li>
            </ul>
          </Box>
        </BoxWrapper>
        <Heading
          content={t("Academics_subtitle6")}
          style={{
            fontSize: "1.5em",
            marginBottom: "15px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
        />

        <div>
          <Table
            striped
            bordered
            hover
            responsive="md"
            style={{ margin: "50px auto" }}
          >
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Person concerned</th>
                <th>E-mail </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1.</td>
                <td>Superintendent (Academic)</td>
                <td>sup.ac@nitjsr.ac.in</td>
              </tr>
              <tr>
                <td>2.</td>
                <td>Dealing Assistant (fee remission & fee demand)</td>
                <td>ministerial1.ac@nitjsr.ac.in</td>
              </tr>
              <tr>
                <td>3.</td>
                <td>
                  Dealing Assistant (examination, result, issuance /
                  verification of certificates & transcript)
                </td>
                <td>ministerial2.ac@nitjsr.ac.in</td>
              </tr>
              <tr>
                <td>4.</td>
                <td>
                  Dealing Assistant (withdrawal, PhD cases, duplicate degree)
                </td>
                <td>ministerial3.ac@nitjsr.ac.in</td>
              </tr>
              <tr>
                <td>5.</td>
                <td>Dealing Assistant (caution money, stipend)</td>
                <td>ministerial4.ac@nitjsr.ac.in</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Container>
    </Fragment>
  );
}

// HumanityBlock style props
AcademicHome.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// AcademicHome default style
AcademicHome.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
  // HumanityBlock col default style
  col: {
    width: ["100%", "50%", "50%"],
    pl: "15px",
    pr: "15px",
    mb: "30px",
  },
  colOne: {
    width: ["100%", "30%", "35%", "30%"],
    mt: [0, "13px", "0"],
    mb: ["30px", 0],
    pl: ["15px", 0],
    pr: ["15px", "15px", 0],
  },
  // Footer col two style
  colTwo: {
    width: ["100%", "70%", "65%", "70%"],
    flexBox: true,
    flexWrap: "wrap",
  },
};

export default AcademicHome;
