import React from "react";
import Container from "../../../Components/UI/Container";
import { useTranslation } from "react-i18next";
import Heading from "../../../Components/Heading";
import {
  SectionWrapper,
  TableWrapper,
} from "../../../assets/css/Custom/AcademicStyle";
import { Icon } from "react-icons-kit";
import { chevronCircleRight } from "react-icons-kit/fa/chevronCircleRight";
import { Helmet } from "react-helmet";
import { FiDownload } from "react-icons/fi";

const object = [
  {
    id: 1,
    text: "No dues form for UG and PG with caution money form",
    link: "https://nitjsr.ac.in/backend/uploads/recents/No%20dues%20form%20for%20UG%20and%20PG%20with%20caution%20money%20form.pdf",
    date: "11/05/2022",
  },
  {
    id: 2,
    text: "FEE REMISSION FORM 2022-23",
    link: "https://nitjsr.ac.in/backend/uploads/recents/FEE%20REMISSION%20FORM%202022-23.pdf",
    date: "13/06/2022",
  },
  {
    id: 3,
    text: "Caution Money Form",
    link: "https://www.nitjsr.ac.in/backend/uploads/recents/Caution%20Money%20Form_New.pdf",
    date: "30/06/2022",
  },
];

function AcademicDocuments({ fontsize }) {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Important documents related to the institute"
        />
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,academics,b.tech,m,tech,p.h.d.,education,engineering,opportunities,departments,studies,documents,notice,calender of nit jsr,schedule of nit jsr"
        />
      </Helmet>
      <Container width="1250px">
        <br />
        <Heading
          content={t("academic_documents_title1")}
          style={{
            fontSize: "1.5em",
            marginBottom: "20px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
        />
        <SectionWrapper>
          <a
            href="https://nitjsr.ac.in/backend/uploads/uploads_2023/Academic Calendar 2023-24/Academic Calendar 2023-2024.pdf"
            className="pdfLink"
          >
            <Icon icon={chevronCircleRight} size={18} />
            &nbsp;&nbsp; {t("academic_documents_title1_link")}
          </a>
        </SectionWrapper>
        <Heading
          content={t("academic_documents_title2")}
          style={{
            fontSize: "1.5em",
            marginBottom: "15px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
        />
        <SectionWrapper>
          <a
            href="https://nitjsr.ac.in/backend/uploads/notices/7ad093d7-dc7b-4986-9cb1-58947d61d93e-Holiday%20List%202023.PDF"
            className="pdfLink"
          >
            <Icon icon={chevronCircleRight} size={18} />
            &nbsp;&nbsp; {t("academic_documents_title2_link")}
          </a>
        </SectionWrapper>

        <Heading
          content={t("academic_documents_title3")}
          style={{
            fontSize: "1.5em",
            marginBottom: "20px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
        />
        <SectionWrapper>
          <TableWrapper style={{ minHeight: "60vh !important" }}>
            {object.map((item, id) => (
              <div
                key={`list_key${id}`}
                className="row border-bottom pb-2 mb-2"
              >
                {/* <div>{item.id}</div> */}
                <div className="col">
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    <b className="text-secondary">{item.text}</b>
                  </a>
                  <div className="row">
                    <div className="col-md-6">
                      {/* <span
                          className="badge badge-pill "
                          style={{ background: "#274086", color: "white" }}
                        > */}
                      {item.date}
                      {/* </span> */}
                    </div>
                    <div className="col-md-6 text-right">
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FiDownload size="25px" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </TableWrapper>
        </SectionWrapper>
      </Container>
    </>
  );
}

export default AcademicDocuments;
