// import React, { useState} from 'react';
// import Sticky from 'react-stickynode';
// import { ThemeProvider } from 'styled-components';
// import {
// 	CharityWrapper,
// 	ContentWrapper,
// 	GlobalStyle,
// } from '../../assets/css/Main/main.style';
// import { ResetCSS } from '../../assets/css/style';
// import DrawerSection from '../../Components/Layout/DrawerSection';
// import Banner from '../../Components/Layout/Banner/Banner';
// import Footer from '../../Components/Layout/Footer';
// import Navbar from '../../Components/Layout/PagesNavbar';
// import Container from '../../Components/UI/Container';
// import { DrawerProvider } from '../../contexts/DrawerContext';
// import { charityTheme } from '../../theme/charity';
// import ConvocationText from './convocation';
// import { Row, Col } from 'react-bootstrap';
// import { Helmet } from 'react-helmet';
// import { useTranslation } from "react-i18next";
// import { ImageWrapper } from "../Home/NoticeSection/Notice.style";
// import Carousel from "./Carousel";

// function Convocation() {

// 	const [ActiveComp] = useState('Convocation');
// 	const { t } = useTranslation();
// 	return (
// 		<ThemeProvider theme={charityTheme}>
// 			<Helmet>
// 				<title>
// 					{t(`${ActiveComp}`)} | {t("NIT Jamshedpur")} | राष्ट्रीय प्रौद्योगिकी संस्थान जमशेदपुर
// 				</title>
// 			</Helmet>
// 			{/* <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
//       <TopSection fontsize={fontsize} setFontSize={setFontSize} />
//         </Sticky> */}
// 			<ResetCSS />
// 			<GlobalStyle />
// 			<CharityWrapper>
// 				<Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
// 					<Navbar />
// 				</Sticky>
// 				<DrawerProvider>
// 					<DrawerSection />
// 				</DrawerProvider>
                
// 				{/* Main Content Starts from here */}
// 				<ContentWrapper >
// 					<Banner heading={t(`${ActiveComp}`)} />
// 					<Container width="90%">
// 						<Row>
// 							<Col md={4} sm={12}>
// 								<ConvocationText />
// 							</Col>
// 							<Col md={8} sm={10}>
//                             <ImageWrapper>
//                                <Carousel />
//                             </ImageWrapper>
// 							</Col>
// 						</Row>
// 					</Container>
// 				</ContentWrapper>
// 				{/* Footer Area */}
// 				<Footer />
// 			</CharityWrapper>
// 		</ThemeProvider>
// 	);
// }

// export default Convocation;


import React, { useState } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { charityTheme } from "../../theme/charity";
import Container from "../../Components/UI/Container/index";

import Notices from "./ConvoContent/notices";
import { Helmet } from "react-helmet";
import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
  // from "../../../assets/css/Main/main.style";
} from "../../assets/css/Main/main.style";
//import Heading from "../../../Components/Heading";
import { useTranslation } from "react-i18next";
import { DrawerProvider } from "../../contexts/DrawerContext";
import Navbar from "../../Components/Layout/PagesNavbar";
import DrawerSection from "../../Components/Layout/DrawerSection";
// "../../../Components/Layout/DrawerSection";
import { ResetCSS } from "../../assets/css/style";
import Banner from "../../Components/Layout/Banner/Banner";
import Footer from "../../Components/Layout/Footer";

function Convocation() {
  const { t } = useTranslation();
  const [ActiveComp] = useState("Convocation 2023");

  return (
    <ThemeProvider theme={charityTheme}>
      <Helmet>
        <title>
          {t(`${ActiveComp}`)} | {t("NIT Jamshedpur")} | राष्ट्रीय प्रौद्योगिकी
          संस्थान जमशेदपुर
        </title>
      </Helmet>
      <ResetCSS />
      <GlobalStyle />
      <CharityWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <Navbar />
        </Sticky>
        <DrawerProvider>
          <DrawerSection />
        </DrawerProvider>
        <ContentWrapper>
          <Banner heading={t(`${ActiveComp}`)} />
        </ContentWrapper>
        <Container>
          <Notices />
        </Container>
        <Container>
        </Container>
        <Footer />
      </CharityWrapper>
    </ThemeProvider>
  );
}

export default Convocation;

