import featureIcon1 from "../images/feature/career_progress.svg";
import { Link } from "react-router-dom";
import featureIcon2 from "../images/feature/Graduation.svg";
import featureIcon3 from "../images/feature/fac2.png";
import featureIconNotice from "../images/feature/notices.png";
import featureIconAlumni from "../images/feature/alumni.png";
import mediaCoverage from "../images/feature/media-coverage.png";
// import featureIcon4 from "../images/feature/team_spirit.svg";
// import featureIcon5 from '../images/feature/admission.svg';
// import featureIcon6 from '../images/feature/research.png';

import mapPin from "../images/events/urja.jpeg";
import searchIcon from "../images/events/ojass.jpeg";
import icon1p from "../images/events/culfest.jpeg";

import humanityGlob from "../images/humanity-glob.png";
import happyKids from "../images/promotion.svg";
import thumb1 from "../images/blog/thumb-1.png";
import thumb2 from "../images/blog/thumb-2.png";

import { Icon } from "react-icons-kit";
import { linkedin } from "react-icons-kit/fa/linkedin";
import { youtubePlay } from "react-icons-kit/fa/youtubePlay";
import { facebook } from "react-icons-kit/fa/facebook";
import { twitter } from "react-icons-kit/fa/twitter";
import { instagram } from "react-icons-kit/fa/instagram";

import img1 from "../images/clubs/pcon.jpeg";
import img2 from "../images/clubs/webteam.jpg";
import img3 from "../images/clubs/bsae.jpeg";
import img4 from "../images/clubs/phoenix_logo.jpg";
import img5 from "../images/clubs/lds.jpeg";
import img6 from "../images/clubs/cds.jpeg";
// import img7 from '../images/clubs/acm.jpeg';
import img8 from "../images/clubs/logo.png";
import img9 from "../images/clubs/innrove.png";
import img10 from "../images/clubs/ees30.png";
import img11 from "../images/clubs/faces.jpeg";
// import img12 from '../images/clubs/csi.jpeg';
import img13 from "../images/clubs/fsae.jpeg";
import img14 from "../images/clubs/phocus1.jpg";
import img15 from "../images/clubs/innov_club.png";
// import img16 from '../images/clubs/proAt.png';
import img17 from "../images/clubs/sports.jpeg";
import img18 from "../images/clubs/quiz.jpg";
import img19 from "../images/clubs/manthan.jpg";
import img20 from "../images/clubs/elan.jpg";
import img21 from "../images/clubs/mes.jpg";
import img22 from "../images/clubs/sece.png";
import img23 from "../images/clubs/theIndomitable.jpeg";
import img24 from "../images/clubs/rachna.jpg";
import img25 from "../images/clubs/softskill.png";
import img26 from "../images/clubs/values.jpg";
import img27 from "../images/clubs/topguns.jpeg";
import img28 from "../images/clubs/nss.jpg";
import img29 from "../images/clubs/rays.jpg";
import img30 from "../images/clubs/earn.jpg";
import img31 from "../images/clubs/sca1.png";
import img32 from "../images/clubs/ces.jpg";
import img33 from "../images/clubs/eco.jpg";
import img34 from "../images/events/ojass.jpeg";
import img35 from "../images/events/culfest.jpeg";
import img36 from "../images/events/urja.jpeg";

import datagov from "../images/clients/datgov.png";
import mkin from "../images/clients/mkin.png";
import indgov from "../images/clients/indgov.png";
import digind from "../images/clients/digind.png";
import AKAM from "../images/clients/AKAM.jpeg";
import G20 from "../images/clients/G20.jpeg";
import mmmd from "../images/smallBanner/mmmd.png";
import partition_rem from "../images/smallBanner/partition_horror.jpeg";
// import Director from "../images/People/person4.png";
// import Deans from "../images/People/deans.png";
// import AssociateDeans from "../images/People/staff1.png";
// import AssistantDeans from "../images/People/staff.png";
// import HOD from "../images/People/hod.png";
// import FacultyIncharge from '../images/People/professors.png';
import Faculty from "../images/People/faculty.png";
// import Registrar from "../images/People/registrar.png";
import Staff from "../images/People/staff.png";
//import Wardens from "../images/People/wardens.png";

/* ------------------------------------ */
// Feature section data
/* ------------------------------------ */

export const featureData = {
  // title: 'Quick Navigations',
  // slogan: 'Being an NIT-Jsrian is like.....',
  features: [
    {
      id: 0,
      icon: mediaCoverage,
      title: "Media Corner",
      link: "/MediaCoverage",
      qlink: "",
      openInNew: true,
    },
    // {
    //   id: 1,
    //   icon: featureIcon2,
    //   title: "Convocation",
    //   description: "If you want to change the world, one wish at a time.",
    //   link: "/convocation",
    //   qlink: "",
    //   openInNew: false,
    // },
    // {
    //   id: 2,
    //   icon: featureIcon4,
    //   title: "Research",
    //   description: "If you want to change the world, one wish at a time.",
    //   link: "/Research",
    //   qlink: "",
    // },
    {
      id: 3,
      icon: featureIcon1,
      title: "Placements",
      description: "If you want to change the world, one wish at a time.",
      // link: "http://archive.nitjsr.ac.in/tap/portfolio/",
      link: "https://www.nitjsr.ac.in/Students/Placements",
      qlink: "",
      openInNew: true,
    },
    // {
    //   id: 4,
    //   icon: featureIcon3,
    //   title: "Central Facilities ",
    //   description: "If you want to change the world, one wish at a time.",
    //   link: "/facilities/Central_Library",
    //   qlink: "",
    //   openInNew: false,
    // },
    {
      id: 4,
      icon: featureIcon3,
      title: "Internal Links",
      description: "Institute trusted websites",
      link: "/internalLinks",
      qlink: "",
      openInNew: false,
    },
    {
      id: 5,
      icon: featureIconNotice,
      title: "Notices",
      description: "Latest Notification & Office Announcement",
      link: "/Notices/Students",
      qlink: "",
      openInNew: false,
    },
    {
      id: 6,
      icon: featureIconAlumni,
      title: "Alumni Corner",
      description: "Alumni Section of NIT Jamshedpur",
      link: "/Alumni",
      qlink: "",
      openInNew: false,
    },
    // {
    //   id: 6,
    //   icon: featureIconNotice,
    //   title: "Media Coverage",
    //   description: " Media Coverage of NIT Jamshedpur",
    //   link: "/Media",
    //   qlink: "",
    //   openInNew: true,
    // }
  ],
};

/* ------------------------------------ */
// Internal Links data
/* ------------------------------------ */
export const internalLinks = [
  {
    id: 1,
    siteName: "Institute Mail Service",
    link: "https://mail.google.com/a/nitjsr.ac.in",
  },
  {
    id: 2,
    siteName: "File Tracking System (FTS)",
    link: "http://online.nitjsr.ac.in/fts/Login.aspx",
  },
  // {
  //   id: 3,
  //   siteName: "Institute e-Library",
  //   link: "http://10.51.12.254:8380/opac/",
  // },
  // {
  //   id: 4,
  //   siteName: "MIS Portal (Outside Institute)",
  //   link: "http://14.139.205.166/nitj",
  // },
  {
    id: 5,
    siteName: "MIS Portal (Within Institute)",
    link: "http://172.16.1.19/NITJ/",
  },
  {
    id: 6,
    siteName: "Samarth ERP",
    link: "https://nitjsr.samarth.ac.in/index.php/site/login",
  },
  {
    id: 7,
    siteName: "Samarth Student Protal",
    link: "https://nitjsr.samarth.edu.in/index.php/site/login",
  },

  {
    id: 8,
    siteName: "Examination Portal",
    link: "http://online.nitjsr.ac.in/endsem/",
  },
  {
    id: 9,
    siteName: "Stipend Portal",
    link: "http://online.nitjsr.ac.in/stipend/",
  },
  // {
  //   id: 7,
  //   siteName: "Admission Portal",
  //   link: "http://14.139.205.166/OnlineAdm",
  // },
  {
    id: 10,
    siteName: "Video Lectures",
    link: "https://sites.nitjsr.ac.in/lecture-videos/",
  },
  {
    id: 11,
    siteName: "Student Result Portal (Within Institute)",
    link: "http://172.16.1.17/StudentPortal/Login.aspx",
  },
  {
    id: 12,
    siteName: "Complaint Management System (CMS)",
    link: "http://online.nitjsr.ac.in/cms/",
  },
  {
    id: 13,
    siteName: "Complaint Against Caste Discrimination",
    link: "https://sites.nitjsr.ac.in/scstComplaintPortal/",
  },
  {
    id: 14,
    siteName: "NIT JSR Official Alumni Network",
    link: "https://www.nitjaa.org/",
  },
  {
    id: 15,
    siteName: "Downloads (only for Students & Employees)",
    link: "https://nitjsr.ac.in/Downloads/Students/",
  },
  {
    id: 16,
    siteName: "Faculty Login",
    link: "https://portal.nitjsr.ac.in",
  },
  {
    id: 17,
    siteName: "Gallery",
    link: "https://nitjsr.ac.in/galleryIndex",
  },
  {
    id: 18,
    siteName: "Library Webpage(intranet)",
    link: " http://10.51.0.20/wordpress",
  },
  {
    id: 19,
    siteName: "Library Online Catalogue(Koha LMS)(intranet)",
    link: " http://10.51.0.10",
  },
  {
    id: 20,
    siteName: "Library E-Resource Remote Access",
    link: " https://idp.nitjsr.ac.in",
  },
  {
    id: 21,
    siteName: "NIT JSR Shodhganga PhDThesis Repository",
    link: " https://shodhganga.inflibnet.ac.in:8080/jspui/handle/10603/436288",
  },
  {
    id: 22,
    siteName: "IRINS Faculty Database",
    link: "  https://nitjsr.irins.org",
  },
  {
    id: 23,
    siteName: "Institutional Digital Repository (IDR)",
    link: " http://14.139.205.163:8080/xmlui",
  },
  {
    id: 24,
    siteName: "QuillBot Premium (Writing Tool)",
    link: "  https://quillbot.com/",
  },
  {
    id: 25,
    siteName: "NIT JSR OPD",
    link: "https://sites.nitjsr.ac.in/nitjsr-opd/",
  },
  {
    id: 26,
    siteName: "RTI-MIS",
    link: "https://rtionline.gov.in/RTIMIS/login/",
  },
  {
    id: 27,
    siteName: "Internal Complaint Committee",
    link: "https://sites.nitjsr.ac.in/icc/",
  },
  {
    id: 28,
    siteName: "Media Corner",
    link: "https://nitjsr.ac.in/MediaCoverage/",
  },
  {
    id: 29,
    siteName: "Alumni Corner",
    link: "https://nitjsr.ac.in/Alumni/",
  },
];

/* ------------------------------------ */
// Branch section data
/* ------------------------------------ */

export const branchData = [
  {
    id: 1,
    menuItem: "Civil Engineering",
    slogan: "Department of Civil Engineering",
    title: `We have <strong>557</strong> students enrolled in <strong>3 Programmes</strong> offered by the Institution namely B.Tech, M.Tech and PhD.`,
    description:
      "The Department of Civil Engineering of National Institute of Technology (formerly Regional Institute of Technology) Jamshedpur was started since the inception of the institute itself in 1960. This core department has sanctioned strength of 33 (thirty-three) faculty members with flexible faculty structure. The department is running UG course for B. Tech. (Hons) degree in Civil Engineering and PG courses as M. Tech. degree in the streams of Structural Engineering, Geotechnical Engineering and Water Resource Engineering as well as Ph. D. program in various streams of Civil Engineering. The department has well qualified and experienced faculty members. Apart from teaching and research, all faculty members are actively involved in administrative woks and other extracurricular activities for all round development of the Institute. Faculty members of the department are also undertaking design, testing & consultancy works for various organizations leading to resource generation for the Institute.",
    linkUrl: "/Departments",
    linkquery: "civil",
    linkText: "SEE MORE",
  },
  {
    id: 2,
    menuItem: "Chemistry",
    slogan: "Department of Chemistry",
    title: `We have <strong>278</strong> students enrolled in <strong>2 Programmes</strong> offered by the Institution namely M.Sc. and PhD.`,
    description:
      "Department of Chemistry, National Institute of Technology, Jamshedpur was originally founded as Regional Institute of Technology in 11260 with the aim of nurturing talent and setting high standards of education and excellence. The Department offers instruction in General Chemistry and Engineering Chemistry to the students in different branches of Engineering students at B.Tech level. The Department started Post Graduate Programme (M.Tech) in Surface Science and Engineering since 1982.The department is equipped with various instruments. Besides teaching, the highly qualified and energetic faculty members of the Department have contributed a lot to elevate the prestige of the institute through their research activities of both basic and applied areas of chemistry.",
    linkUrl: "/Departments",
    linkquery: "chem",
    linkText: "SEE MORE",
  },
  {
    id: 3,
    menuItem: "Computer Applications",
    slogan: "Department of Computer Applications",
    title: `We have <strong>278</strong> students enrolled in <strong>1 Programme</strong> offered by the Institution namely MCA.`,
    description:
      "The Department of Computer Applications at the National Institute of Technology Jamshedpur, was formed in 1987. Since its inception, the department has always been recognized all over the country for its excellence. The Department has consistently produced quality professionals in the field of Computer & Network and strived for excellence in research and development. MIS of NIT Jamshedpur is managed by Department of Computer Applications.",
    linkUrl: "/Departments",
    linkquery: "mca",
    linkText: "SEE MORE",
  },
  {
    id: 4,
    menuItem: "Computer Science and Engineering",
    slogan: "Department of Computer Science and Engineering",
    title: `We have <strong>557</strong> students enrolled in <strong>3 Programmes</strong> offered by the Institution namely B.Tech, M.Tech and PhD.`,
    description:
      "The Department of Computer Science and Engineering at the National Institute of Technology Jamshedpur, was formed in 1992. Since its inception, the department has always been recognized all over the country for its excellence. The Department has consistently produced quality professionals in the field of Computer Science & Engineering and strived for excellence in research and development.",
    linkUrl: "/Departments",
    linkquery: "cs",
    linkText: "SEE MORE",
  },
  {
    id: 5,
    menuItem: "Electronics and Communication Engineering",
    slogan: "Department of Electronics and Communication Engineering",
    title: `We have <strong>557</strong> students enrolled in <strong>3 Programmes</strong> offered by the Institution namely B.Tech, M.Tech and PhD.`,
    description:
      "Department of Electronics and Communication Engineering was started in 1989. The department covers a host of subjects inclusive of Electronic Circuits, Microprocessor, Digital signal processing, Analog communication, Digital communication, Mobile communication, VLSI, Embedded systems, Instrumentation etc. The department has laboratories catering to all the subjects of studies. There are five research scholars working in different specializations under the groups of Communication Engineering, VLSI and Embedded systems, Signal Processing, Instrumentation and Soft computing. The Department has highly motivated faculty pool to cater our needs.",
    linkUrl: "/Departments",
    linkquery: "ece",
    linkText: "SEE MORE",
  },
  {
    id: 6,
    menuItem: "Electrical Engineering",
    slogan: "Department of Electrical Engineering",
    title: `We have <strong>557</strong> students enrolled in <strong>3 Programmes</strong> offered by the Institution namely B.Tech, M.Tech and PhD.`,
    description:
      "The Department of Electrical Engineering was started in 1960. The Department has been consistently producing quality Electrical Engineers since its inception and is also involved in research and development activities. The alumni of the department are well placed in both public and private sectors. In addition to the UG program the department runs PG program in Power Systems and Ph.D. program in different areas of specialization.",
    linkUrl: "/Departments",
    linkquery: "eee",
    linkText: "SEE MORE",
  },
  {
    id: 7,
    menuItem: "Humanities, Social Sciences and Management",
    slogan: "Department of Humanities, Social Sciences and Management",
    title: `The Department was engaged in the teaching of English, Philosophy, Psychology, Political Science and Economics to B.Sc. (Engg.) students.`,
    description:
      "When the institute was established in 1960 as Regional Institute of Technology, the Department came into being as Humanities Department and was fashioned on the lines of the Department of Humanities and Social Sciences at IITs. The Department was engaged in the teaching of English, Philosophy, Psychology, Political Science and Economics to B.Sc. (Engg.) students. In 2014, the Department was upgraded to the Department of Humanities, Social Sciences and Management. The department is currently engaged in teaching English for Communication, Introduction to Soft Skills, Organizational Behaviour and Industrial Psychology, Industrial Economics and Accountancy, Management Information System courses to B Tech (Hons.) students and Technical Communication to M.Tech,Communication kills to M.Sc,Financial Management ,Drag Behaviour and Management to MCA and Electives(principles of eco entrepreneurship Development). The faculty members of the department are also training students in the area of oral and written communication skills for placement purposes.The department plans to start a two year full time, post graduate MBA programme in near future.",
    linkUrl: "/Departments",
    linkquery: "humanities",
    linkText: "SEE MORE",
  },
  {
    id: 8,
    menuItem: "Mathematics",
    slogan: "Department of Mathematics",
    title: `We have <strong>278</strong> students enrolled in <strong>2 Programmes</strong> offered by the Institution namely M.Sc. and PhD.`,
    description:
      "Department of Mathematics was established since the inception of National Institute of Technology as Regional Institute of Technology, Jamshedpur in 1960. The Department provides an outstanding research environment complemented by excellent teaching for its students to flourish in different areas of academics and industry as well. The department has produced a good number of Ph.D students. The students of the department are well placed in leading Academic Institution, Research and Development organizations and Software industries like IIT’s, ONGC, IBM, WIPRO etc. Currently, Department has initiated M.Sc. Mathematics programme. Also, Department is planning to start new progammes like, 2-year M.Sc. in Applied Mathematics and 5-year Integrated M.Sc. programmes.",
    linkUrl: "/Departments",
    linkquery: "maths",
    linkText: "SEE MORE",
  },
  {
    id: 9,
    menuItem: "Mechanical Engineering",
    slogan: "Department of Mechanical Engineering",
    title: `We have <strong>557</strong> students enrolled in <strong>3 Programmes</strong> offered by the Institution namely B.Tech, M.Tech and PhD.`,
    description:
      "The Department of Mechanical Engineering is one of the oldest (started in 1960) and the largest department in terms of faculty, students and activities. The Department offers B.Tech (Hons.) in Mechanical Engineering, M.Tech in Computer Integrated Design & Manufacturing (CIDM), M.Tech in Thermal Engineering (TE) and M.Tech in Energy System (ES). Department is also running PhD Program in various research areas in the Mechanical Engineering. It also offers part time PhD program, mainly meant for those employed in industries and academic institutions. The department is having highly qualified and experienced faculty in all streams of Mechanical Engineering. An interactive relationship is maintained between the students and staff which enables the students to develop a sound foundation in the stream in a conduciven environment.",
    linkUrl: "/Departments",
    linkquery: "mech",
    linkText: "SEE MORE",
  },
  {
    id: 10,
    menuItem: "Metallurgical and Materials Engineering",
    slogan: "Department of Metallurgy",
    title: `We have <strong>557</strong> students enrolled in <strong>3 Programmes</strong> offered by the Institution namely B.Tech, M.Tech and PhD.`,
    description:
      "The Department of Metallurgical and Materials Engineering was amongst the four branches with which the institute was initially launched in 1960, with increasing focus on : Materials Technology, Foundry Technology, Iron & Steel, Nanotechnology, Mineral Benefaction and the R&D work being carried out in these fields in the metallurgical sector, Materials Engineering was included in the curriculum of the department in 1995.There are M.Tech course on Materials Technology & Foundry Technology.",
    linkUrl: "/Departments",
    linkquery: "meta",
    linkText: "SEE MORE",
  },

  {
    id: 11,
    menuItem: "Physics",
    slogan: "Department of Physics",
    title: `We have <strong>278</strong> students enrolled in <strong>2 Programmes</strong> offered by the Institution namely M.Sc. and PhD.`,
    description:
      "The Department of Physics at the National Institute of Technology, Jamshedpur, was formed in 1960. Since its inception, the department has been recognized all over the country for its excellence. The department has consistently produced quality professionals in the field of physics and strived for excellence in research and development. The Department has at present 9 faculty members including DST-Inspire Faculty and 11 research scholars engaged in doctoral research.The Department actively participates in front-line research in several major areas of Physics including Solar Energy, Nuclear scattering and Supersymmetry, Mathematical Physics, Holography and holographic optical element, Speckle Meterology, Condensed Matter Physics, Plasma Physics, Computational Physics and Spectroscopy.At present department offer 2 years Master of Science (Physics) Programme as well as a Ph.D. Programme with specialization in various major areas of Physics. The Department also participates in teaching Engineering Physics to B.Tech. (Hons.) students of all the disciplines namely- Civil Engineering, Computer Science and Engineering, Electrical and Electronics Engineering, Electronics and Communication Engineering, Production and Industrial Engineering, Mechanical Engineering and Metallurgical & Materials Engineering of the Institute. Our M.Sc. students are ranking good in national level competitive exams like CSIR-NET, GATE, JEST as well as in the INSPIRE Fellow scheme offered by the Department of Science and Technology (DST), India.",
    linkUrl: "/Departments",
    linkquery: "phys",
    linkText: "SEE MORE",
  },
  {
    id: 12,
    menuItem: "Production and Industrial Engineering",
    slogan: "Department of Manufacture Engineering",
    title: `We have <strong>557</strong> students enrolled in <strong>3 Programmes</strong> offered by the Institution namely B.Tech, M.Tech and PhD.`,
    description:
      "The Department of Production & Industrial Engineering was started in year 1988 with the name of “Production Engineering & Management”. The name of this department was changed to Production & Industrial Engineering in the year 2007. The department was accredited by NBA in 2009. This core department has a sanctioned strength of 16 (Sixteen) faculty members with flexible faculty structure. Presently, there are 02 Professor, 03 Associate professors and 08 Assistant Professors. Every faculty member has received doctorate degree in different research areas. The department is running a UG course for B. Tech. (Hons.) degree in Production and industrial Engineering and a PG course in Manufacturing Systems Engineering as well as Ph. D. program in various streams of Production and Industrial Engineering. The Department is currently developing different engineering laboratories like CAD/CAM Lab., Advanced Manufacturing and Flexible manufacturing systems Lab., Non- Traditional Manufacturing lab, and Industrial Engineering & Ergonomics lab and Central workshop. Time to time curricula/syllabus is upgraded as per the market and technology requirement. The department has also proposed to start a new PG course in Production Management which will cater to the current industrial need.",
    linkUrl: "/Departments",
    linkquery: "prod",
    linkText: "SEE MORE",
  },
];

/* ------------------------------------ */
// Work feature data
/* ------------------------------------ */

export const workData = {
  title: "Upcoming Events",
  slogan:
    "National Institute of Technology, Jamshedpur is an Institute of National Importance, under MHRD.",
  features: [
    {
      id: 1,
      icon: mapPin,
      title: "Urja",
      description:
        "We track every dollar you raise, and show the projects you helped fund with photos.",
    },
    {
      id: 2,
      icon: searchIcon,
      title: "OJASS",
      description:
        "We’ve consistently received the highest grades available for accountability.",
    },
    {
      id: 3,
      icon: icon1p,
      title: "Culfest",
      description:
        "We’ve consistently received the highest grades available for accountability.",
    },
    {
      id: 4,
      icon: mapPin,
      title: "Urja",
      description:
        "We’ve consistently received the highest grades available for accountability.",
    },
  ],
};
/* ------------------------------------ */
// Milestone block data
/* ------------------------------------ */
export const milestoneData = {
  // title: 'OUR FIRST MILESTONE',
  // amount: '$4M',
  // text: 'RAISED TO DATE',
  counterItems: [
    {
      id: 1,
      amount: "3000+",
      title: "Students",
    },
    {
      id: 2,
      amount: "150+",
      title: "Faculty",
    },
    {
      id: 3,
      amount: "13",
      title: "Branches",
    },
    {
      id: 4,
      amount: "50+",
      title: "Events",
    },
  ],
};

/* ------------------------------------ */
// Humanity block data
/* ------------------------------------ */

export const humanityData = {
  image: humanityGlob,
  slogan: "Vision and Mission",
  title: "Our Vision ",
  title2: "Our Mission",
  text: "To be one of the premier technical institutions for its academic excellence and innovative research to meet the future needs of the society.",
  lists: [
    {
      id: 1,
      text: "1. To build conducive environment for learning and creativity.",
    },
    {
      id: 2,
      text: "2. To train students to become technically competent professionals and socially responsible citizens.",
    },
    {
      id: 3,
      text: "3. To develop innovative products and technologies for the betterment of the society.",
    },
  ],
};

/* ------------------------------------ */
// Promotion block data
/* ------------------------------------ */

export const promotionData = {
  slogan: "GET YOUR COMMUNITY ON BOARD",
  title: "We are creating a world with basic safety for all.",
  text1:
    "We exist to permanently end violent conict and exploitation facing our world’s most isolated and unprotected communities.",
  text2:
    "We partner with local visionaries in volatile conict zones to build community-run initiatives and drive global policy change to end violent conict, save lives, and give communities the safety they deserve.",
  lists: [
    {
      id: 1,
      text: "Let them drink pure",
    },
    {
      id: 2,
      text: "Ensure them medicare",
    },
    {
      id: 3,
      text: "create opportunity of education",
    },
  ],
  image: happyKids,
};

/* ------------------------------------ */
// Donation form data
/* ------------------------------------ */
export const paymentPolicy = [
  {
    id: 1,
    title: "One Time",
    value: "oneTime",
    text: "One Time donation given",
  },
  {
    id: 2,
    title: "Ongoing",
    value: "ongoing",
    text: "Everymonth donation given",
  },
];

export const currencyOptions = [
  {
    id: 1,
    value: "usd",
    title: "$ USD",
  },
  {
    id: 2,
    value: "gbp",
    title: "£ GBP",
  },
  {
    id: 3,
    value: "cny",
    title: "¥ CNY",
  },
];

/* ------------------------------------ */
// Blog post data
/* ------------------------------------ */

export const posts = [
  {
    id: 1,
    title: "BRITs Week 2019 together with O2 is here",
    excerpt:
      "72 million children around the world are not enrolled in school. Concern Worldwide focuses on providing basic education to those…",
    thumbUrl: thumb1,
    btnText: "Learn More ",
    btnUrl: "#1",
  },
  {
    id: 2,
    title: "BRITs Week 2019 together with O2 is here",
    excerpt:
      "72 million children around the world are not enrolled in school. Concern Worldwide focuses on providing basic education to those…",
    thumbUrl: thumb2,
    btnText: "Learn More ",
    btnUrl: "#1",
  },
  {
    id: 3,
    title: "BRITs Week 2019 together with O2 is here",
    excerpt:
      "72 million children around the world are not enrolled in school. Concern Worldwide focuses on providing basic education to those…",
    thumbUrl: thumb1,
    btnText: "Learn More ",
    btnUrl: "#1",
  },
  {
    id: 4,
    title: "BRITs Week 2019 together with O2 is here",
    excerpt:
      "72 million children around the world are not enrolled in school. Concern Worldwide focuses on providing basic education to those…",
    thumbUrl: thumb2,
    btnText: "Learn More ",
    btnUrl: "#1",
  },
];

export const clients = [
  {
    id: 1,
    logo: datagov,
    name: "windows",
    link: "https://data.gov.in/",
  },
  {
    id: 2,
    logo: mkin,
    name: "airbnb",
    link: "https://www.makeinindia.com/home/",
  },
  {
    id: 3,
    logo: indgov,
    name: "adidas",
    link: "https://www.india.gov.in/",
  },
  {
    id: 4,
    logo: digind,
    name: "ibm",
    link: "https://digitalindia.gov.in/",
  },
  {
    id: 5,
    logo: AKAM,
    name: "Azadi Ka Amrit Mahotsav",
    link: "https://amritmahotsav.nic.in/",
  },
  {
    id: 6,
    logo: G20,
    name: "G20",
    link: "https://www.g20.org/en/",
  },
  {
    id: 7,
    logo: mmmd,
    name: "Meri Maati Mera Desh",
    link: "https://merimaatimeradesh.gov.in/",
  },
  {
    id: 7,
    logo: partition_rem,
    name: "Partition Horror Remembrance Day",
    link: "https://drive.google.com/drive/u/1/folders/1Eakk7hIRSOkIAmkHuytDKuAj4lg-lbHA",
  },
  // {
  //   id: 5,
  //   logo: amazon,
  //   name: 'amazon',
  //   link: '#5',
  // },
  // {
  //   id: 6,
  //   logo: google,
  //   name: 'google',
  //   link: '#6',
  // },
];

/* ------------------------------------ */
// Footer data section
/* ------------------------------------ */
export const menuWidgets = [
  {
    id: 1,
    title: "Quick Links",
    menu: [
      {
        id: 1,
        text: "Legal Cases",
        link: "/Legal-cases",
      },
      {
        id: 2,
        text: "Right To Information",
        link: "/RTI",
      },
      {
        id: 4,
        text: "PAN & GST Details",
        link: "/PAN&GST",
      },
      {
        id: 5,
        text: "Photo Gallery",
        link: "/galleryIndex",
      },
    ],
  },
  {
    id: 2,
    title: "Quick Links",
    menu: [
      {
        id: 1,
        text: "Downloads",
        link: "/Downloads/Students",
      },
      {
        id: 2,
        text: "SiteMap",
        link: "/Sitemap",
      },
    ],
  },
  {
    id: 3,
    title: "Useful Links",
    menu: [
      {
        id: 1,
        text: "National Knowledge Network",
        link: "http://nkn.gov.in/",
      },
      {
        id: 2,
        text: "PM Research Fellowship",
        link: "https://pmrf.in/",
      },
    ],
  },
];

export const copyright = [
  {
    id: 1,
    text: "Privacy Policy",
    link: "1#",
  },
  {
    id: 2,
    text: "Terms and Conditions",
    link: "1#",
  },
];

export const socialLinks = [
  {
    id: 5,
    icon: <Icon icon={youtubePlay} />,
    name: "youtube",
    link: "https://www.youtube.com/channel/UCSKf3jRXKuA9QkPsu69tzkQ",
  },
  {
    id: 1,
    icon: <Icon icon={linkedin} />,
    name: "linkedin",
    link: "https://www.linkedin.com/school/national-institute-of-technology-jamshedpur/mycompany/",
  },
  {
    id: 2,
    icon: <Icon icon={facebook} />,
    name: "facebook",
    link: "https://www.facebook.com/NITJamshedpurOfficial/",
  },
  {
    id: 3,
    icon: <Icon icon={twitter} />,
    name: "twitter",
    link: "https://twitter.com/jamshedpur_nit?s=08",
  },
  // {
  //   id: 4,
  //   icon: <Icon icon={github} />,
  //   name: "github",
  //   link: "4#",
  // },
  {
    id: 4,
    icon: <Icon icon={instagram} />,
    name: "instagram",
    link: "https://www.instagram.com/nitjamshedpur_official/",
  },
];

//Sidebar data (Pages)

//AcademicData
export const AcademicMenuData = [
  {
    id: 1,
    text: "Academics",
    path: "/academic/Academics",
  },
  {
    id: 2,
    text: "Admissions",
    path: "/academic/Admissions",
  },
  {
    id: 3,
    text: "People",
    path: "/academic/People",
  },
  {
    id: 4,
    text: "Departments",
    path: "/academic/Departments",
  },
  {
    id: 5,
    text: "Curriculum",
    path: "/academic/Curriculum",
  },
  {
    id: 6,
    text: "Student_Statistics",
    path: "/academic/Student_Statistics",
  },
  {
    id: 7,
    text: "Fee_Structure",
    path: "/academic/Fee_Structure",
  },
  {
    id: 8,
    text: "Semester_Schedule",
    path: "/academic/Semester_Schedule",
  },
  {
    id: 9,
    text: "Ordinance",
    path: "/academic/Ordinance",
  },
  {
    id: 10,
    text: "Academic Documents",
    path: "/academic/Academic_Documents",
  },
];

//institute menu data
export const InstituteMenuData = [
  {
    id: 1,
    path: "/Institute/About_NITJSR",
    text: "About_NITJSR",
  },
  {
    id: 2,
    path: "/Institute/Vision_and_Mission",
    text: "Vision_and_Mission",
  },
  {
    id: 3,
    path: "/Institute/Acts_and_Statues",
    text: "Acts_and_Statues",
  },
  {
    id: 4,
    path: "/Institute/Board_of_Governers",
    text: "Board_of_Governers",
  },
  {
    id: 5,
    path: "/Institute/Finance_Committee",
    text: "Finance_Committee",
  },
  {
    id: 6,
    path: "/Institute/Senate",
    text: "Senate",
  },
  {
    id: 7,
    path: "/Institute/rnc_nitjsr",
    text: "Research_and_Consultancy",
  },
  {
    id: 8,
    path: "/Institute/Buildings_and_Works_Committee",
    text: "BWC",
  },
  {
    id: 9,
    path: "/Institute/Organizational_Chart",
    text: "Organizational_Chart",
  },
  {
    id: 10,
    path: "/Institute/Ranking_and_Recognition",
    text: "Ranking_and_Recognition",
  },
  {
    id: 11,
    path: "/Institute/Former_Directors",
    text: "Former_Directors",
  },
  {
    id: 12,
    path: "/Institute/How_to_Reach",
    text: "How_to_Reach",
  },
];

//administration menu data
export const AdministrationMenuData = [
  {
    text: "Visitor",
    path: "/Administration/Visitor",
    type: "visitor",
  },
  {
    text: "Chairman,_BOG",
    path: "/Administration/Chairman,_BOG",
    type: "chairman,bog",
  },
  {
    text: "Director",
    path: "/Administration/Director",
    type: "director",
  },
  {
    text: "Deputy Director",
    path: "/Administration/deputy_director",
    type: "deputydirector",
  },
  {
    text: "Registrar",
    path: "/Administration/Registrar",
    type: "registrar",
  },
  {
    text: "Deans",
    path: "/Administration/Deans",
    type: "dean",
  },
  {
    text: "Associate Deans",
    path: "/Administration/Associate_Deans",
    type: "associatedean",
  },
  {
    text: "Head of Departments",
    path: "/Administration/Head_of_Departments",
    type: "hod",
  },
  {
    text: "Chief Vigilance Officer",
    path: "/Administration/Chief_Vigilance_Officer",
    type: "CVO",
  },
];

export const TenderMenuData = [
  {
    id: 1,
    text: "Active Tenders",
    path: "/Tender/Active_Tenders",
  },
  {
    id: 2,
    text: "Archived Tenders",
    path: "/Tender/Archived_Tenders",
  },
  {
    id: 3,
    text: "All Tenders",
    path: "/Tender/All_Tenders",
  },
  {
    id: 4,
    text: "Contact Us",
    path: "/Tender/Contact_Us",
  },
  {
    id: 5,
    text: "Useful Links",
    path: "/Tender/Useful_Links",
  },
];
export const NoticesMenuData = [
  {
    id: 1,
    text: "Announcements",
    path: "/Notices/Announcements",
  },
  {
    id: 2,
    text: "Office Orders",
    path: "/Notices/Office_Orders",
  },
  {
    id: 3,
    text: "Students",
    path: "/Notices/Students",
  },
  {
    id: 4,
    text: "Recruitments",
    path: "/Recruitments",
  },
  {
    id: 5,
    text: "Archive Notices",
    path: "/Notices/Archive_Notices",
  },
];
export const DownloadsMenuData = [
  {
    id: 1,
    text: "Students",
    path: "/Downloads/Students",
  },
  {
    id: 2,
    text: "Employees",
    path: "/Downloads/Employees",
  },
];
export const ResearchesMenuData = [
  {
    id: 1,
    text: "Introduction",
    path: "/Research?type=Introduction",
  },
  {
    id: 2,
    text: "Research Data",
    path: "/Research?type=Research_Data",
  },
  {
    id: 3,
    text: "Collaborative Projects",
    path: "/Research?type=Collaborative_Projects",
  },
  {
    id: 4,
    text: "Consultancy",
    path: "/Research?type=Consultancy",
  },
  {
    id: 5,
    text: "News and Events",
    path: "/Research?type=News_and_Events",
  },
  {
    id: 6,
    text: "Office Holders",
    path: "/Research?type=Office_Holders",
  },
];

//Cell Menu
export const CellMenuData = [
  {
    id: 1,
    text: "SC-ST Cell",
    path: "/Cell/SC-ST_Cell",
  },
  {
    id: 2,
    text: "IPR Cell",
    path: "/Cell/IPR_Cell",
  },
  {
    id: 3,
    text: "Grievance Cell",
    path: "/Cell/Grievance_Cell",
  },
  {
    id: 4,
    text: "Reservation Cell",
    path: "/Cell/Reservation_Cell",
  },
  {
    id: 5,
    text: "Publication Cell",
    path: "/Cell/Publication_Cell",
  },
  {
    id: 6,
    text: "Public Relation Cell",
    path: "/Cell/Public_Relation_Cell",
  },
  {
    id: 7,
    text: "Hindi Cell",
    path: "/Cell/Hindi_Cell",
  },
  {
    id: 8,
    text: "Internal Complaints Committee(ICC)",
    path: "/Cell/Internal_Complaints_Committee(ICC)",
  },
  {
    id: 9,
    text: "Indian Knowledge Systems",
    path: "/Iks",
  },
];

//	Central Facilities data
export const CentralFacilitiesData = [
  {
    id: 1,
    text: "Central Library",
    path: "/facilities/Central_Library",
  },
  {
    id: 2,
    text: "Medical Facilities",
    path: "/facilities/Medical_Facilities",
  },
  {
    id: 3,
    text: "Computer Center",
    path: "/facilities/Computer_Center",
  },
  {
    id: 4,
    text: "Safety and Security",
    path: "/facilities/Safety_and_Security",
  },
  // {
  //   id: 5,
  //   text: "Hostels",
  //   path: "/facilities/Hostels",
  // },
  {
    id: 5,
    text: "Guest House",
    path: "/facilities/Guest_House",
  },
  // {
  //   id: 7,
  //   text: "Student Counselling",
  //   path: "/facilities/Student_Counselling",
  // },
  {
    id: 6,
    text: "Transport",
    path: "/facilities/Transport",
  },
];

export const StudentMenuData = [
  {
    id: 1,
    text: "Placements",
    path: "/Students/Placements",
  },
  {
    id: 2,
    text: "Student Achievements",
    path: "/Students/Student-Achievements",
  },
  {
    id: 3,
    text: "Student Activities",
    path: "/Students/Student-Activities",
  },
  {
    id: 4,
    text: "Financial Assistance",
    path: "/Students/Financial-Assistance",
  },
  {
    id: 5,
    text: "Hostel Management",
    path: "/Students/Hostel-Management",
  },
  {
    id: 6,
    text: "Wardens",
    path: "/Students/Wardens",
  },
  {
    id: 7,
    text: "Anti Ragging",
    path: "/Students/Anti-Ragging",
  },
  // {
  //   id: 8,
  //   text: "Student Life",
  //   path: "/Students/Student-Life",
  // },
  // {
  //   id: 9,
  //   text: "Student Counselling",
  //   path: "/Students/Student_Counselling",
  // },
  {
    id: 10,
    text: "Health and Welfare",
    path: "/Students/Health-and-Welfare",
  },
  {
    id: 11,
    text: "Life @NIT JSR",
    path: "/Students/Life-@NIT-JSR",
  },
  {
    id: 12,
    text: "Student Council",
    path: "/Students/Student-Council",
  },
  // {
  //   id: 9,
  //   text: 'Admissions',
  //   path: '/Students/Admissions',
  // }
];

/* ------------------------------------ */
// People section data
/* ------------------------------------ */

export const peopleMenu = [
  // {
  //   image: Director,
  //   text: "People Home",
  //   path: "/People/People_Home",
  //   type: "people",
  // },
  // {
  //   image: Director,
  //   text: "Director",
  //   path: "/People/Director",
  //   type: "director",
  // },
  // {
  //   image: Director,
  //   text: "Deputy Director",
  //   path: "/people/profile/ME115",
  //   type: "deputydirector",
  // },
  // {
  //   image: Registrar,
  //   text: "Registrar",
  //   path: "/People/Registrar",
  //   type: "registrar",
  // },
  // {
  //   image: Deans,
  //   text: "Deans",
  //   path: "/People/Deans",
  //   type: "dean",
  // },
  // {
  //   image: AssociateDeans,
  //   text: "Associate Deans",
  //   path: "/People/Associate_Deans",
  //   type: "associatedean",
  // },
  // {
  //   image: HOD,
  //   text: "Head of Departments",
  //   path: "/People/Head_Of_Departments",
  //   type: "hod",
  // },
  // {
  // 	image: FacultyIncharge,
  // 	text: 'Faculty In-Charge',
  // 	path: '/People/Faculty_InCharge',
  // 	type: 'professor',
  // },
  {
    image: Faculty,
    text: "Faculty",
    path: "/People/Faculty",
    type: "faculty",
  },
  {
    image: Staff,
    text: "Office Administration",
    path: "/People/Staff",
    type: "staff",
  },
  {
    image: Staff,
    text: "Other Administration",
    path: "/People/Other Administration",
    type: "staff",
  },
  /* {
	  image: './components/images/administration.png',
	  text: 'Administration',
	  path: '/People/Sections/Administration',
	  type: 'administration',
	} */
  /* {
		image: './components/images/alumni.png',
		text: 'Alumni',
		path: '/People?label=Alumni&activeComp=2&type=alumni',
		type: 'alumni'
	},
	{
		image: './images/research.png',
		text: 'Research',
		path: '/People?label=Research&activeComp=2&type=research',
		type: 'research'
	} */
];
/* ------------------------------------ */
// Navbar people subMenu
/* ------------------------------------ */

// const peopleMenuNav = [...peopleMenu];
// peopleMenuNav.unshift({
//   image: './images/research.png',
//   text: 'People Home',
//   path: '/People?label=People%20Home&activeComp=1&type=people',
//   type: 'people',
// });

/**************************
 * TAP MENU DATA
 */
export const TAPMenuData = [
  {
    id: 1,
    text: "about_tap",
    path: "/tap/about_tap",
  },
  {
    id: 2,
    text: "director_desk_tap",
    path: "/tap/director_desk_tap",
  },
  {
    id: 3,
    text: "tpo_desk_tap",
    path: "/tap/tpo_desk_tap",
  },
  {
    id: 4,
    text: "statistics_tap",
    path: "/tap/statistics_tap",
  },
  {
    id: 5,
    text: "our_recruiters_tap",
    path: "/tap/our_recruiters_tap",
  },
  {
    id: 6,
    text: "contact_tap",
    path: "/tap/contact_tap",
  },
  // {
  //   id:3,
  //   text:""
  // }
  // {
  //   id: 2,
  //   text: "Our Coordinator",
  //   path: "/tap/Our_Coordinator",
  // },
  // {
  //   id: 3,
  //   text: "Top Recruiters",
  //   path: "/tap/Top_Recruiters",
  // },
  // {
  //   id: 4,
  //   text: "Message from CPO",
  //   path: "/tap/Message_From_CPO",
  // },
  // {
  //   id: 5,
  //   text: "Statistics",
  //   path: "/tap/Statistics",
  // },
];

export const FacilitiesData = [
  {
    id: 1,
    text: "Central Library",
    path: "Central_Library",
  },
  {
    id: 2,
    text: "Medical Facilities",
    path: "Medical_Facilities",
  },
  // {
  //   id:3,
  //   text:"Central Workshop"
  // },
  {
    id: 4,
    text: "Computer Center",
    path: "Computer_Center",
  },
  {
    id: 6,
    text: "Safety and Security",
    path: "Safety_and_Security",
  },
  {
    id: 7,
    text: "Guest House",
    path: "Guest_House",
  },
  {
    id: 8,
    text: "Transport",
    path: "Transport",
  },
];

export const RncData = [
  // {
  //   id: 1,
  //   text: "Research Area",
  //   path: "/ResearchCon/Research_Area",
  // },
  // {
  //   id: 2,
  //   text: "Research Publications",
  //   path: "/ResearchCon/Research_Publications",
  // },
  // {
  //   id: 3,
  //   text: "MOU",
  //   path: "/ResearchCon/MOU",
  // },
  // {
  //   id: 4,
  //   text: "Research Projects",
  //   path: "/ResearchCon/Research_Projects",
  // },
  // {
  //   id: 6,
  //   text: "Research Facilities",
  //   path: "/ResearchCon/Research_Facilities",
  // },
  // {
  //   id: 7,
  //   text: "Awards & Achievements",
  //   path: "/ResearchCon/Awards",
  // },
  // {
  //   id: 8,
  //   text: "Entrepreneurship",
  //   path: "/ResearchCon/Entrepreneurship",
  // },
  // {
  //   id: 9,
  //   text: "Documents",
  //   path: "/ResearchCon/Documents",
  // },
  // {
  //   id: 10,
  //   text: "Contact Us",
  //   path: "/ResearchCon/Contact_Us",
  // },
];

/* ------------------------------------ */
// Navbar menu
/* ------------------------------------ */

export const menuItems = [
  {
    label: "Institute",
    path: "/Institute",
    offset: "81",
    submenu: InstituteMenuData,
  },
  {
    label: "Administration",
    path: "/Administration",
    offset: "81",
    submenu: AdministrationMenuData,
  },
  {
    label: "Academics",
    path: "/Academic",
    offset: "100",
    submenu: AcademicMenuData,
  },
  {
    label: "Students",
    path: "/Students",
    submenu: StudentMenuData,
  },
  {
    label: "Research",
    // path: "/RnD",
    path: "ResearchCon",
    offset: "84",
  },
  {
    label: "People",
    path: "/People",
    offset: "81",
    submenu: peopleMenu,
  },
  // {
  //   label: "Placements",
  //   path: "/tap",
  //   offset: "81",
  //   submenu: TAPMenuData,
  // },
  {
    label: "Tender",
    path: "/Tender",
    offset: "81",
    submenu: TenderMenuData,
  },
  {
    label: "Notices",
    path: "/Notices",
    offset: "81",
    submenu: NoticesMenuData,
  },
  {
    label: "Cells",
    path: "/Cell",
    offset: "81",
    submenu: CellMenuData,
  },
  {
    label: "Central Facilities",
    path: "/Cell",
    offset: "81",
    submenu: CentralFacilitiesData,
  },

  {
    label: "Recruitments",
    path: "/Recruitments",
    offset: "83",
  },
  // {
  //   label: 'Research and Innovations',
  //   path: '/Research',
  //   offset: '81',
  //   submenu: ResearchesMenuData,
  // },

  {
    label: "RTI",
    path: "/RTI",
    offset: "85",
  },
];

//====================================================================
//Clubs Data(Student Activities)
//importing images/logo of clubs

export const ClubsData = [
  {
    id: 34,
    title: "OJASS",
    image: img34,
    path: "",
    queryString: "OJASS",
  },
  {
    id: 35,
    title: "Culfest",
    image: img35,
    path: "",
    queryString: "culfest",
  },
  {
    id: 36,
    title: "Urja",
    image: img36,
    path: "",
    queryString: "urja",
  },
  {
    id: 1,
    title: "Programming ClubNIT Jamshedpur",
    image: img1,
    path: "",
    queryString: "PCON",
  },
  {
    id: 2,
    title: "Web Team",
    image: img2,
    path: "/Clubs/Webteam",
    queryString: "WebTeam",
  },
  {
    id: 3,
    title: "Daksh",
    image: img3,
    path: "",
    queryString: "Daksh",
  },
  {
    id: 4,
    title: "Phoenix",
    image: img4,
    path: "",
    queryString: "Phoenix",
  },
  {
    id: 5,
    title: "Litrary and Debating Society",
    image: img5,
    path: "",
    queryString: "LDS",
  },
  {
    id: 6,
    title: "Cultural and Dramatic Society",
    image: img6,
    path: "",
    queryString: "CDS",
  },
  {
    id: 8,
    title: "Entrepreneurship Cell",
    image: img8,
    path: "",
    queryString: "CDS",
  },
  {
    id: 9,
    title: " Innoreva",
    image: img9,
    path: "",
    queryString: "CDS",
  },
  {
    id: 10,
    title: "Pravardhan-Department of Electrical Engineering",
    image: img10,
    path: "",
    queryString: "CDS",
  },
  {
    id: 11,
    title: " FACES(Fine Arts Club for Engineering Students)",
    image: img11,
    path: "",
    queryString: "CDS",
  },
  {
    id: 13,
    title: " Drift",
    image: img13,
    path: "",
    queryString: "CDS",
  },
  {
    id: 14,
    title: " Photography cum Filmaking Club(Phocus)",
    image: img14,
    path: "",
    queryString: "CDS",
  },
  {
    id: 15,
    title: "Innovation Club, NIT Jamshedpur",
    image: img15,
    path: "",
    queryString: "CDS",
  },
  {
    id: 17,
    title: " Sports",
    image: img17,
    path: "",
    queryString: "CDS",
  },
  {
    id: 18,
    title: " QuNITe",
    image: img18,
    path: "",
    queryString: "CDS",
  },
  {
    id: 19,
    title: " Manthan",
    image: img19,
    path: "",
    queryString: "CDS",
  },
  {
    id: 20,
    title: "Revanta",
    image: img20,
    path: "",
    queryString: "CDS",
  },
  {
    id: 21,
    title: "Mechanical Engineering Society(MES)",
    image: img21,
    path: "",
    queryString: "CDS",
  },
  {
    id: 22,
    title: "SECE",
    image: img22,
    path: "",
    queryString: "CDS",
  },
  {
    id: 23,
    title: "The Indomitable",
    image: img23,
    path: "",
    queryString: "CDS",
  },
  {
    id: 24,
    title: " Rachna",
    image: img24,
    path: "",
    queryString: "CDS",
  },
  {
    id: 25,
    title: " Soft Skills Club",
    image: img25,
    path: "",
    queryString: "CDS",
  },
  {
    id: 26,
    title: "Values and Ethics Club",
    image: img26,
    path: "",
    queryString: "CDS",
  },
  {
    id: 27,
    title: " Topguns",
    image: img27,
    path: "",
    queryString: "CDS",
  },
  {
    id: 28,
    title: " National Service Scheme(NSS)",
    image: img28,
    path: "",
    queryString: "CDS",
  },
  {
    id: 29,
    title: " Rays",
    image: img29,
    path: "",
    queryString: "CDS",
  },
  {
    id: 30,
    title: "Earn and Learn",
    image: img30,
    path: "",
    queryString: "CDS",
  },
  {
    id: 31,
    title: " Society of Computer Applications",
    image: img31,
    path: "",
    queryString: "CDS",
  },
  {
    id: 32,
    title: "Civil Engineering Society",
    image: img32,
    path: "",
    queryString: "CDS",
  },
  {
    id: 33,
    title: "Team ECO RIDERS",
    image: img33,
    path: "",
    queryString: "CDS",
  },
];

export const peopleDummyData = [
  {
    title: "Dr A B C",
    text: "This is a wider card with supporting text below .",
  },
  {
    title: "Dr X Y Z",
    text: "This is a wider card with supporting text below .",
  },
  {
    title: "Dr P Q R",
    text: "This is a wider card with supporting text below .",
  },
  {
    title: "Dr A B C",
    text: "This is a wider card with supporting text below .",
  },
  {
    title: "Dr X Y Z",
    text: "This is a wider card with supporting text below .",
  },
  {
    title: "Dr P Q R",
    text: "This is a wider card with supporting text below .",
  },
  {
    title: "Dr A B C",
    text: "This is a wider card with supporting text below .",
  },
  {
    title: "Dr X Y Z",
    text: "This is a wider card with supporting text below .",
  },
  {
    title: "Dr P Q R",
    text: "This is a wider card with supporting text below .",
  },
  {
    title: "Dr A B C",
    text: "This is a wider card with supporting text below .",
  },
];
