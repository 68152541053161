import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../Components/UI/Container";
import Heading from "../../../Components/Heading";
import Minutes from "../../../Components/Minutes/minutes";
import { TableWrapper } from "../../../assets/css/Custom/AcademicStyle";
import Skeleton from "react-loading-skeleton";
import { getRequest } from "../../../functions/request";
import { Helmet } from "react-helmet";
function Senate() {
  const { t } = useTranslation();
  const [minutes, setMinutes] = useState([]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [memebers, setMemebers] = useState([]);
  const [loadingMemeber, setLoadingMemeber] = useState(true);
  useEffect(() => {
    getRequest('administration?type=senate')
      .then((res) => res.data.data)
      .then((res) => {
        setMemebers(res);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingMemeber(false));

    getRequest('Minutes?type=senate')
      .then((res) => res.data.results)
      .then((res) =>
        res.map((e) => ({
          name: e.heading,
          link: `${e.PATH}`,
        }))
      )
      .then((res) => setMinutes(res))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Fragment>
      <Container width="1250px">
        <Helmet>
          <meta name="description" content="The Senate" />
          <meta
            name="keywords"
            content="nit jamshedpur,nit jsr,nit,jamshedpur,technology,Director, conferences ,about Us,Computer center,services,manthan"
          />
        </Helmet>
        <br />
        <Heading
          style={{
            fontSize: "1.5em",
            marginBottom: "20px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
          content={t("head_senate_members")}
        />
        {loadingMemeber && <Skeleton count={10} />}
        {!loadingMemeber && (
          <TableWrapper>
            <table className="table">
              <thead>
                <tr id="headRow">
                  <th>{t("people_title1_thead1")}</th>
                  <th>{t("people_title1_thead2")}</th>
                  <th>{t("people_title1_thead3")}</th>
                </tr>
              </thead>
              <tr>
                <td data-column="Name">{t("s_mem1_name")}</td>
                <td data-column="Post">{t("s_mem_1_post")}</td>
                <td data-column="Email">{t("s_mem1_mail")}</td>
              </tr>

              <tr>
                <td data-column="Name">{t("s_mem2_name")}</td>
                <td data-column="Post">{t("s_mem_2_post")}</td>
                <td data-column="Email">{t("s_mem2_mail")}</td>
              </tr>
              <tr>
                <td data-column="Name">{t("s_mem3_name")}</td>
                <td data-column="Post">{t("s_mem_3_post")}</td>
                <td data-column="Email">{t("s_mem3_mail")}</td>
              </tr>
              <tr>
                <td data-column="Name">{t("s_mem4_name")}</td>
                <td data-column="Post">{t("s_mem_4_post")}</td>
                <td data-column="Email">{t("s_mem4_mail")}</td>
              </tr>
              <tr>
                <td data-column="Name">{t("s_mem5_name")}</td>
                <td data-column="Post">{t("s_mem_5_post")}</td>
                <td data-column="Email">{t("s_mem5_mail")}</td>
              </tr>
              <tr>
                <td data-column="Name">{t("s_mem6_name")}</td>
                <td data-column="Post">{t("s_mem_6_post")}</td>
                <td data-column="Email">{t("s_mem6_mail")}</td>
              </tr>
              {/* <tr>
                <td data-column="Name">{t("s_mem7_name")}</td>
                <td data-column="Post">{t("s_mem_7_post")}</td>
                <td data-column="Email">{t("s_mem7_mail")}</td>
              </tr> */}
              <tr>
                <td data-column="Name">{t("s_mem8_name")}</td>
                <td data-column="Post">{t("s_mem_8_post")}</td>
                <td data-column="Email">{t("s_mem8_mail")}</td>
              </tr>
              <tr>
                <td data-column="Name">{t("s_mem9_name")}</td>
                <td data-column="Post">{t("s_mem_9_post")}</td>
                <td data-column="Email">{t("s_mem9_mail")}</td>
              </tr>
              <tr>
                <td data-column="Name">{t("s_mem10_name")}</td>
                <td data-column="Post">{t("s_mem_10_post")}</td>
                <td data-column="Email">{t("s_mem10_mail")}</td>
              </tr>
              <tr>
                <td data-column="Name">{t("s_mem11_name")}</td>
                <td data-column="Post">{t("s_mem11_post")}</td>
                <td data-column="Email">{t("s_mem11_mail")}</td>
              </tr>
              <tr>
                <td data-column="Name">{t("s_mem12_name")}</td>
                <td data-column="Post">{t("s_mem_12_post")}</td>
                <td data-column="Email">{t("s_mem12_mail")}</td>
              </tr>
              
              <tr>
                <td data-column="Name">{t("s_mem14_name")}</td>
                <td data-column="Post">{t("s_mem_14_post")}</td>
                <td data-column="Email">{t("s_mem14_mail")}</td>
              </tr>
              <tr>
                <td data-column="Name">{t("s_mem15_name")}</td>
                <td data-column="Post">{t("s_mem_15_post")}</td>
                <td data-column="Email">{t("s_mem15_mail")}</td>
              </tr>
              <tr>
                <td data-column="Name">{t("s_mem16_name")}</td>
                <td data-column="Post">{t("s_mem_16_post")}</td>
                <td data-column="Email">{t("s_mem16_mail")}</td>
              </tr>
              <tr>
                <td data-column="Name">{t("s_mem17_name")}</td>
                <td data-column="Post">{t("s_mem_17_post")}</td>
                <td data-column="Email">{t("s_mem17_mail")}</td>
              </tr>
              <tr>
                <td data-column="Name">{t("s_mem18_name")}</td>
                <td data-column="Post">{t("s_mem_18_post")}</td>
                <td data-column="Email">{t("s_mem18_mail")}</td>
              </tr>
              <tr>
                <td data-column="Name">{t("s_mem20_name")}</td>
                <td data-column="Post">{t("s_mem_20_post")}</td>
                <td data-column="Email">{t("s_mem20_mail")}</td>
              </tr>
              <tr>
                <td data-column="Name">{t("s_mem21_name")}</td>
                <td data-column="Post">{t("s_mem_21_post")}</td>
                <td data-column="Email">{t("s_mem21_mail")}</td>
              </tr>
              <tr>
                <td data-column="Name">{t("s_mem19_name")}</td>
                <td data-column="Post">{t("s_mem_19_post")}</td>
                <td data-column="Email">{t("s_mem19_mail")}</td>
              </tr>
            </table>
          </TableWrapper>
        )}
        <Heading
          style={{
            fontSize: "1.5em",
            marginBottom: "20px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
          content={t("heading-min_of_meet_senate")}
        />
        {loading && <Skeleton count={5} />}
        {!loading && <Minutes minutes={minutes} />}
      </Container>
    </Fragment>
  );
}

export default Senate;
