import "./media.css";
import React, { useState, useEffect } from "react";
import { getRequest } from "../../../functions/request";
import { CardActionArea, CardContent, Link } from "@mui/material";
import { Box } from "@mui/system";
import { Card, Grid, Typography } from "@mui/material";
import { CustomTestimonialGrid } from "../../../assets/css/Custom/CustomTestimonialGrid";
import { useTranslation } from "react-i18next";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { border, zIndex } from "styled-system";
import { data } from "../../Home/CurrentAchievement/data";

// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

var settings = {
  dots: true,
  infinite: true,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: true,
  autoplay: true,
  autoplaySpeed: 4000,
  arrows: false,
};
// content

// const object = [
//   {
//     content:
//       "A comprehensive training programme on ‘Smart Distribution System and Storage’ has commenced at the Indian Institute of Technology Kanpur (IIT-K).",
//     img: "#",
//     title: "Training program on challenges in power sector starts at IIT-K",
//     date: "24 August 2023",
//     link: "#",
//   },
//   {
//     content:
//       "The Indian Institute of Technology Kanpur (IITK) is gearing up for its annual sports festival, Udghosh 2023 and in order to bring the festival closer to school students, the institute has announced Udghosh National Open School Quiz (UNOSQ) for students from classes 5 to 12.",
//     img: "#",
//     title: "IIT Kanpur to organise Udghosh National Open School Quiz",
//     date: "24 August 2023",
//     link: "#",
//   },
// ];

function NewsRenderer({
  title,
  description,
  published_date,
  source_link,
  links,
}) {
  const arr = links.split(",");
  // const [isHovered, setIsHovered] = useState(false);
  return (
    <Box
      xs={12}
      sm={8}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "left",
        justifyContent: "space-evenly",
        overflowY: "auto",
      }}
      padding={1}
      marginTop={0}
    >
      {/* Responsive card  */}
      <Card style={{ width: "100%" }}>
        <CardActionArea style={{ maxWidth: "100%" }}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              alignSelf={"center"}
              textAlign={"center"}
            ></Typography>
            {/* <Typography variant="body2" color="text.secondary">
              {description}
            </Typography> */}
            <Box
              xs={12}
              sm={6}
              md={3}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                overflowY: "auto",
                maxWidth: "100%",
              }}
              // padding={2}
            >
              {/* <Grid style={{ alignSelf: "left" }}>
                Published Date:{published_date}
              </Grid> */}
              {/* <Grid>
                <Link href={source_link} target="_blank" underline="always">
                  Source
                </Link>
              </Grid> */}

              <Grid
                container
                spacing={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                {arr.map((link) => (
                  <Grid
                    container
                    spacing={0.7}
                    item
                    sm={12}
                    xs={4}
                    md={4}
                    style={{
                      boxSizing: "border-box",
                      display: "flex",

                      justifyContent: "center",
                      overflow: "hidden",
                      height: "150px",
                      width: "150px",
                    }}
                  >
                    <a target="__blank" href={"https://nitjsr.ac.in/backend/"+link}><img
                      style={{
                        height: "inherit",
                        width: "inherit",
                        transition: "transform 0.3s ease",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      src={"https://nitjsr.ac.in/backend/" + link}
                      alt="image"
                      onMouseOver={(e) => {
                        e.currentTarget.style.transform = "scale(1.2)";
                        //e.currentTarget.style.zIndex = "1000";
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.transform = "scale(1)";
                        // e.currentTarget.style.zIndex = "1";
                      }}
                    />
                    </a>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
}

function transformData(data) {
  const result = {};

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Iterate through the data
  data.forEach((item) => {
    const date = new Date(item.published_date);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Month is zero-based, so add 1 to get the actual month
    const monthName = monthNames[month - 1]; // Subtract 1 to get the correct index

    if (!result[year]) {
      result[year] = {};
    }
    if (!result[year][monthName]) {
      result[year][monthName] = [];
    }

    result[year][monthName].push({
      title: item.title,
      description: item.description,
      source_link: item.source_link,
      published_date: date.toDateString(),
      links: item.links,
    });
  });

  return result;
}

function Media() {
  const { t } = useTranslation();
  const [d, setD] = useState([{}]);
  const [title, setTitle] = useState({});
  const [image, setImage] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getRequest(`/media-coverage/fetch`, false);
        console.log(res.data);

        const data = transformData(res.data);
        //console.log(data);
        setD(data);
        // let a = {};
        // let b = [];
        // for (let year in data.keys()) {
        //   for (let month in data[year].keys()) {
        //     a[(year, month)].push(data[year][month].title);
        //   }
        // }
        // console.log(a);
        // setTitle(a);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  //  When we will get the destination api to hit for the news them instead of sample object[] array we will use the Notices[] array
  return (
    <div className="wrapper">
      <Card
        sx={{
          borderRadius: "12",

          pt: "1rem",
          px: "2rem",
        }}
      >
        {Object.keys(d).map((year) => (
          <Accordion key={year}>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                fontSize: "1.5em",
                marginBottom: "20px",
                marginTop: "13px",
                fontWeight: "500",
                background: "#274187",
                color: "#fff",
                padding: "1% 1% 1% 3%",
              }}
            >
              <Typography sx={{ color: "#fff", fontSize: "1em" }}>
                {year}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              {Object.keys(d[year]).map((month) => (
                <Accordion>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      fontSize: "1.5em",
                      marginBottom: "20px",
                      marginTop: "13px",
                      fontWeight: "500",
                      background: "#274187",
                      color: "#fff",
                      padding: "1% 1% 1% 3%",
                    }}
                  >
                    <Typography sx={{ color: "#fff", fontSize: "1em" }}>
                      {month}
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography fontSize="2.5rem">
                      <ul>
                        {d[year][month].map((item) => (
                          <li>{item.title}</li>
                        ))}
                      </ul>
                    </Typography>

                    {d[year][month].map((props) => (
                      <NewsRenderer
                        {...props}
                        // published_date={data[year][month].published_date}
                        // key={`${year}+${month}`}
                      />
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Card>
    </div>
  );
}
export default Media;
