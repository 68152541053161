import React, { useState } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { charityTheme } from "../../theme/charity";
import Container from "../../Components/UI/Container/index";
import AlumniContent from "./AlumniContent";
import Notices from "./AlumniContent/notices";
import { Helmet } from "react-helmet";
import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
  // from "../../../assets/css/Main/main.style";
} from "../../assets/css/Main/main.style";
//import Heading from "../../../Components/Heading";
import { useTranslation } from "react-i18next";
import { DrawerProvider } from "../../contexts/DrawerContext";
import Navbar from "../../Components/Layout/PagesNavbar";
import DrawerSection from "../../Components/Layout/DrawerSection";
// "../../../Components/Layout/DrawerSection";
import { ResetCSS } from "../../assets/css/style";
import Banner from "../../Components/Layout/Banner/Banner";
import Footer from "../../Components/Layout/Footer";

function Alumni() {
  const { t } = useTranslation();
  const [ActiveComp] = useState("Alumni Corner");

  return (
    <ThemeProvider theme={charityTheme}>
      <Helmet>
        <title>
          {t(`${ActiveComp}`)} | {t("NIT Jamshedpur")} | राष्ट्रीय प्रौद्योगिकी
          संस्थान जमशेदपुर
        </title>
      </Helmet>
      <ResetCSS />
      <GlobalStyle />
      <CharityWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <Navbar />
        </Sticky>
        <DrawerProvider>
          <DrawerSection />
        </DrawerProvider>
        <ContentWrapper>
          <Banner heading={t(`${ActiveComp}`)} />
        </ContentWrapper>
        <Container>
          <Notices />
        </Container>
        <Container>
          <AlumniContent /> 
        </Container>
        <Footer />
      </CharityWrapper>
    </ThemeProvider>
  );
}

export default Alumni;
