import React from 'react'
//import List from '../Components/List';
import {useTranslation} from "react-i18next";
import Container from "../../../Components/UI/Container";

import {StaffData} from '../../../assets/data/StaffData';
import Heading from '../../../Components/Heading';
import { TableWrapper } from "../../../assets/css/Custom/AcademicStyle";

function Staff() {
    const {t} = useTranslation();
    return (
        <div>
            
            <Container>
              <br/>
              {StaffData.map((dept) => (

                <>
                <Heading content = {t(dept.section)}/>
                <TableWrapper>
                  <table className="table">
                  <thead>
                    <tr id="headRow">
                      <th>{t("Name")}</th>
                      <th>{t("Designation")}</th>
                      <th>{t("Mobile")}</th>
                      <th>{t("Email")}</th>
                    </tr> 
                  </thead>
                  
                    {dept.staff.map((person) => {
                      return (
                        <tr>
                          <td data-column={t("Name")}>
                            {t(person.name)}
                          </td>
                          <td data-column={t("Designation")}>
                            {t(person.designation)}
                          </td>
                          <td data-column={t("Mobile")}>
                            {t(person.mobile)}
                          </td>
                          <td data-column={t("Email")}>
                            {person.email}
                          </td>
                        </tr>
                      )
                    }
                  )}


                  </table>
                </TableWrapper>
                </>
              ))}
              
                
          </Container>
            
        </div>
    )
}

export default Staff
