import { Link, Pagination, Stack } from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { FiDownload } from "react-icons/fi";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import {
  CharityWrapper,
  ContentWrapper,
  GlobalStyle,
} from "../../assets/css/Main/main.style";
import { ResetCSS } from "../../assets/css/style";
import Banner from "../../Components/Layout/Banner/Banner";
import DrawerSection from "../../Components/Layout/DrawerSection";
import Footer from "../../Components/Layout/Footer";
import Navbar from "../../Components/Layout/PagesNavbar";
import Container from "../../Components/UI/Container";
import { DrawerProvider } from "../../contexts/DrawerContext";
import { getRequest } from "../../functions/request";
import { formatDateString, getFileURL } from "../../functions/utils";
import { mainTheme } from "../../theme/main";
import Heading from "../../Components/Heading";

const ArchiveEvents = () => {
  const { t } = useTranslation();
  const [data, setData] = React.useState([]);
  const fetching = async () => {
    try {
      let res = await getRequest(`/events/archive-events`);
      setData(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    fetching();
    console.log("data  = ", data);
    console.log("Page no : ", page);
  }, []);

  data.reverse();

  const eventsPerPage = 9;
  const pageCount = Math.ceil(data.length / eventsPerPage);
  let currPageEvents = data.slice(0, eventsPerPage);

  const [page, setPage] = useState(1);

  const StartEventNo = (page - 1) * eventsPerPage;
  const endEventNo = page * eventsPerPage;
  currPageEvents = data.slice(StartEventNo, endEventNo);
  const paginate = (e, value) => {
    setPage(value);

    window.scrollTo(0, 0);
  };

  return (
    <ThemeProvider theme={mainTheme}>
      <Helmet>
        <title>
          {t("Recruitments")} | {t("NIT Jamshedpur")} | राष्ट्रीय प्रौद्योगिकी
          संस्थान जमशेदपुर
        </title>
        <meta name="description" content="Archive events, NIT Jamshedpur" />
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,Archive Events nit jsr,Archive Events,Archive events,notices"
        />
      </Helmet>
      <ResetCSS />
      <GlobalStyle />

      <CharityWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <Navbar />
        </Sticky>
        <DrawerProvider>
          <DrawerSection black="black" white="white" />
        </DrawerProvider>
        <ContentWrapper>
          <Banner heading={t("Admission_btech")} />
          <Container className="mt-10">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "20px",
                textAlign: "center",
              }}
            >
              <Heading content="Admission Notices for various courses, 2024-25" />
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                fontSize: "20px",
                margin: "10px",
              }}
            >
              <div>
                <strong>1.</strong> Bachelor of Technology 2024-2025
              </div>
              <Link
                href="https://nitjsr.ac.in/backend/uploads/admissions/Dates_for_Physical_Reporting_for_UG_and_PG_Admissions.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {"Link"}
              </Link>
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                fontSize: "20px",
                margin: "10px",
              }}
            >
              <div>
                <strong>2.</strong> Master of Design in Product Design
              </div>
              <Link
                href="https://nitjsr.ac.in/backend/uploads/admissions/MDes.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Link
              </Link>
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                fontSize: "20px",
                margin: "10px",
              }}
            >
              <div>
                <strong>3.</strong> SOP for physical reporting of 2024 batch
                students
              </div>
              <Link
                href="https://nitjsr.ac.in/backend/uploads/notices/2b88510b-ca73-4816-b50a-72e591d253ae-Standard%20Operating%20Procedure%20(SOP)%20regarding%20Physical%20Reporting%20for%20the%20candidates%20who%20have%20taken%20Admission%20through%20JoSAA%20CSAB%20DASA%20MEA%20CCMT%20CCMN%20NIMCET%20IAT%202024%20at%20NIT%20Jamshedpur.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {"Link"}
              </Link>
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                fontSize: "20px",
                margin: "10px",
              }}
            >
              <div>
                <div>
                  <strong>
                    Under Full-Time, Part-Time and Sponsored Category (Last
                    date: August 21, 2024)
                  </strong>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    fontSize: "20px",
                    margin: "10px",
                  }}
                >
                  <strong>1.</strong> Admission to M. Tech and M.Des. Programmes
                  <Link
                    href="https://nitjsr.ac.in/backend/uploads/admissions/Revised_Notice_for_Admission_to_M_Tech_and_M_Des_Programme_For_Autumn_Semester_AY_2024_2025.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {"Link"}
                  </Link>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    fontSize: "20px",
                    margin: "10px",
                  }}
                >
                  {/* <strong>2.</strong> Postponement of IAT -2024
                  <Link
                    href="https://nitjsr.ac.in/backend/uploads/notices/1b06d969-9c00-4f7d-b6e5-d520fbf1d451-NOTICE%E2%80%93S%E2%80%9387-2024%20-Postponement%20and%20Extension%20of%20Institute%20Admission%20Test%20(IAT)%20%E2%80%93%202024%20for%20all%20M.Tech%20(Full-Time,%20Part-Time%20and%20Sponsored%20Category)%20and%20M.Des.%20Programme%20(Full%20Time)%20till%20further%20notice%20(1).pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {"Link"}
                  </Link> */}
                  <strong>2.</strong> Postponement of Institute Admission Test
                  -2024
                  <Link
                    href="https://nitjsr.ac.in/backend/uploads/recruitments/b862fa76-714c-48e9-ade9-11bc9610eb41-M.Tech%20[Full%20Time%20and%20Part%20Time]%20&%20M.Des.%20[Full%20Time%20]%20Seat%20Vacancy%20Status%20for%20IAT%202024-25%20and%20Revised%20Timeline.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {"Link"}
                  </Link>
                </div>
              </div>
            </div>
          </Container>
        </ContentWrapper>
        <Footer />
      </CharityWrapper>
    </ThemeProvider>
  );
};

export default ArchiveEvents;
